import * as React from 'react';
import {Form} from './../../../components/MyPage';
import {enhance} from './enhancer';

function Main(props: any) {
  return (
    <div className="a__mypage-main">
      <div className="b__title c__after-title">
        <h1>マイページ</h1>
      </div>
      <div className="b__form">
        <Form edit {...{...props}} />
      </div>
    </div>
  );
}

export default enhance((props: any) => <Main {...props} />);
