import {env} from './env';
import {auth} from './auth';
import {location} from './location';
import {axios} from './axios';

const app = {
  env,
  auth,
  location,
  axios,
};

export {app};
