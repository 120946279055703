import React, {useEffect} from 'react';
import {enhance} from './enhancer';
import Table from '@/migration/table/Table';
import {Field} from 'redux-form';
import {Search} from '@/migration/search';
import {UsersTableColumns} from './../../../components/Users';
import {lifecycle} from './lifecycle';
import {setState} from './state';
import {handler} from './handler';

import './index.scss';

function UsersMain(props: any) {
  const {handleSubmit, users} = props;
  const state = setState();
  lifecycle.componentDidMount(state, props);
  useEffect(() => {
    if (!users.pages.active) return;
    users.pages.active.forEach(({id, fee}) => {
      const name = `fee${id}`;
      props.change(name, fee);
    });
  }, [users.pages.active]);

  return (
    <div className="a__users-main">
      <div className="b__title c__after-title">
        <h1 style={{fontFamily: 'UD Shin Go Medium'}}> ユーザー </h1>
      </div>

      <div className="b__caution">
        <p>
          ※ (例)
          手数料に15と入力した場合、15％の手数料が掛かることになり、デフォルトの10％(5%)は無視されます。
        </p>
      </div>

      <div className="b__sub">
        <div className="b__search">
          <Field
            name="searchWord"
            component={Search}
            placeholder="検索ワードを入力"
            addSearchWord={handler.addSearchWord(state, props)}
            searchWords={props.users.searchWords}
            removeSearchWord={handler.removeSearchWord(state, props)}
            outline
          />
        </div>
      </div>

      <form
        className="b__users"
        onSubmit={handleSubmit(async (values: any) => {
          await handler.submit(values, props, state);
        })}
      >
        <div className="b__users-create">
          <button disabled={state.loading}>登録</button>
        </div>
        <Table
          active={props.users.pages.active}
          columns={UsersTableColumns(props, state, handler)}
          current={props.users.current}
          totalCount={props.users.totalCount}
          loading={state.loading || props.users.loading}
          updateCurrent={handler.updateCurrent(state, props)}
          disabled={state.disabled}
          sortState={props.users.sortState}
          updateSortState={handler.updateSortState(state, props)}
          pageSize={50}
        />
      </form>
    </div>
  );
}

export default enhance((props: any) => <UsersMain {...props} />);
