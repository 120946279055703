import React from 'react';

import './index.scss';

const SemanticInput: React.FC<any> = (props) => {
  const {input, ...rest} = props;

  return (
    <input
      {...rest}
      className="u-input"
      autoComplete={'off'}
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      maxLength={3}
    />
  );
};

export default SemanticInput;
