import * as React from 'react';
import {Link} from 'react-router-dom';
import Input from '../Input';
import {config} from './../../../config';
import {Field} from 'redux-form';
import {Icon} from 'semantic-ui-react';
import {utils} from '../../../lib/utils';

import './index.scss';

const TableColumns = (props: any, state: any, handler: any) => {
  const {paths} = config;

  return [
    {
      Header: 'ID',
      width: 100,
      sortable: true,
      accessor: 'id',
    },
    {
      Header: 'ニックネーム',
      sortable: true,
      width: 260,
      accessor: 'displayName',
      Cell: (row: any) => {
        return (
          <>
            <Link
              to={`${paths.users.main}/${row.original.id}`}
              className="user-nickname"
            >
              {utils.str.replaceTofu(`${row.original.displayName}`)}
            </Link>
          </>
        );
      },
    },
    {
      Header: '手数料',
      sortable: false,
      width: 200,
      Cell: (row: any) => {
        const fee = `fee${row.original.id}`;

        return (
          <>
            <Field name={fee} component={Input} />
            <button
              className="user-fee-delete"
              disabled={state.loading}
              onClick={async () => {
                await handler.deleteFee(row.original.id, props, state);
              }}
            >
              <Icon name="trash" />
            </button>
          </>
        );
      },
    },
    {
      Header: 'メールアドレス',
      width: 300,
      sortable: false,
      accessor: 'identityCode',
    },
    {
      Header: '会社名または屋号名',
      sortable: false,
      accessor: 'company',
    },
    {
      Header: 'Stripe ID',
      sortable: false,
      accessor: 'stripeAccountId',
    },
  ];
};

export default TableColumns;
