import * as React from 'react';
import {enhance} from './enhancer';
import {MailsForm} from './../../../components/Mails';
import {setState} from './stete';
import {lifecycle} from './lifecycle';

function Edit(props: any) {
  const state = setState();
  lifecycle.componentDidMount(props);

  return (
    <div className="a__mails-edit">
      <div className="b__title c__after-title">
        <h1>メール詳細</h1>
      </div>
      <div className="b__form">
        <MailsForm detail {...{...props, ...state}} />
      </div>
    </div>
  );
}

export default enhance((props: any) => <Edit {...props} />);
