import * as React from 'react';
import {Route} from 'react-router-dom';
import {config} from '@/config';
import {SalesMain, SalesEdit} from '@/pages/Sales';

function UsersRoutes() {
  return (
    <>
      <Route exact path={config.paths.sales.main} component={SalesMain} />
      <Route exact path={config.paths.sales.edit} component={SalesEdit} />
    </>
  );
}
export default UsersRoutes;
