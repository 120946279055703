const users = (values: any) => {
  const errors: any = {};

  const identityCode = values.identityCode;
  const about = values.about;
  const displayName = values.displayName;
  const company = values.company;
  const boat = values.boat;

  // メールアドレス
  if (!identityCode) {
    errors.identityCode = 'メールアドレスを入力してください';
  } else if (identityCode) {
    if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(identityCode.trim())
    ) {
      errors.identityCode = 'メールアドレスが正しい形式ではありません';
    }
  }

  if (about && about.length > 1000) {
    // 自己紹介
    errors.about = '自己紹介は1,000文字以内で入力してください';
  }

  if (!displayName) {
    // ニックネーム
    errors.displayName = 'ニックネームを入力してください';
  } else if (displayName && displayName.length > 20) {
    errors.displayName = 'ニックネームは20文字以内で入力してください';
  }

  if (company && company.length > 20) {
    // 会社名
    errors.company = '会社名は20文字以内で入力してください';
  }

  if (boat && boat.length > 20) {
    // よく利用する釣船
    errors.boat = 'よく利用する釣船は20文字以内で入力してください';
  }

  return errors;
};

export {users};
