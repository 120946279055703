import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import {Loader} from 'semantic-ui-react';
import {Props} from './types';

const Thumb = (props: Props) => {
  return (
    <div className="thumb_outer" key={props.file.original}>
      <div
        className={classNames({
          thumb_image_outer: true,
          upload: props.isImageLoading,
        })}
      >
        {props.isImageLoading ? (
          <div className="loader_outer">
            <div className="loader_wrapper">
              <Loader active inline />
            </div>
          </div>
        ) : null}

        {_.has(props.file, 'thumb') ? (
          <img
            src={props.file.thumb}
            className="thumb_image"
            style={{...props.file.thumbStyle}}
          />
        ) : (
          <img
            src={props.file}
            className="thumb_image"
            style={{...props.file.thumbStyle}}
          />
        )}

        <div className="w__item-text">
          <div
            className="w-icon"
            onClick={() => {
              props.deleteDroppedImage(props.file);
            }}
            role="button"
            tabIndex={0}
          >
            削除
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thumb;
