import React from 'react';
import {Input} from '@/migration/input';

import {GcInputProps} from './types';

import './index.scss';

const GcInput: React.FC<GcInputProps> = (props) => {
  return <Input {...props} />;
};

export default GcInput;
