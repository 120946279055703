import * as React from 'react';
import {Button} from 'semantic-ui-react';
import {setState} from './state';

import './index.scss';

function EditModal(props: any) {
  const {
    handleCloseModal,
    submitForm,
    selectedForm: {
      newsEdit: {values},
    },
  } = props;
  const state = setState();

  return (
    <div className="a__news-edit-modal">
      <div className="b__title c__before-title">
        <h2>変更内容を保存しますか？</h2>
      </div>
      {values.isDisclosed ? (
        <div className="b__messages">
          <p>「公開する」にすると、サイト上に表示されます。</p>
        </div>
      ) : null}
      <div className="b__actions">
        <Button
          type="button"
          onClick={handleCloseModal}
          disabled={state.loading}
        >
          キャンセル
        </Button>
        <Button
          positive
          className="submit"
          onClick={() => {
            submitForm(props, values);
          }}
          disabled={state.loading}
          loading={state.loading}
        >
          変更を保存する
        </Button>
      </div>
    </div>
  );
}

export default EditModal;
