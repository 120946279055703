import _ from 'lodash';
import {utils} from '../utils';
import {ISale} from '@/@types/models/sales';

const PRICE = {
  MIN: 50,
  MAX: 10000001,
};

const WEIGHT = {
  MIN: 1,
  MAX: 10000000,
};

export const create = (values: ISale) => {
  const errors: any = {};

  // ダミーユーザー
  if (!values.dummyUserId) {
    errors.dummyUserId = 'ダミーユーザーを選択してください';
  }

  // 商品名
  if (!values.name) {
    errors.name = '商品名を入力してください';
  }

  // 画像
  if (_.isEmpty(values.sellImages)) {
    errors.sellImages = '画像を選択してください';
  }

  // サイズ
  // if (!values.size) {
  //   // errors.size = 'サイズを入力してください';
  // } else if (values.size) {
  //   const size: any = values.size;
  //   if (!/^[0-9]+$/.test(size)) {
  //     errors.size = '半角数字を入力してください';
  //   } else if (/^[0-9]+$/.test(size)) {
  //     if (size < 1) {
  //       errors.size = '1以上の数字を入力してください';
  //     } else if (size > 10000) {
  //       errors.size = '10,000以下の数字を入力してください';
  //     }
  //   }
  // }

  // 重量(任意)
  if (values.weight) {
    const weight: any = values.weight;
    if (!/^[0-9]+$/.test(weight)) {
      errors.weight = '半角数字を入力してください';
    } else {
      if (weight < WEIGHT.MIN) {
        errors.weight = `${WEIGHT.MIN}以上の数字を入力してください`;
      } else if (weight > WEIGHT.MAX) {
        errors.weight = `${WEIGHT.MAX.toLocaleString()}以下の数字を入力してください`;
      }
    }
  }

  // 配送料の負担
  if (!values.shippingExpense) {
    errors.shippingExpense = '配送料の負担を入力してください';
  }

  // 配送までの日数
  if (!values.shippingTiming) {
    errors.shippingTiming = '配送までの日数を入力してください';
  }

  // 配送元の地域
  if (!values.area) {
    errors.area = '発送元の地域を入力してください';
  }

  // 締め方,処理方法
  if (!values.ikejimeMethod) {
    errors.ikejimeMethod = '締め方、処理方法を入力してください';
  }

  // 保管方法
  if (!values.savingMethod) {
    errors.savingMethod = '保管方法を入力してください';
  }

  // 配送方法
  if (!values.deliveryMethod) {
    errors.deliveryMethod = '配送方法を入力してください';
  }

  // 価格
  if (!values.price) {
    errors.price = '出品時の開始価格を入力してください';
  } else {
    const price: any = Number(values.price);

    if (price < PRICE.MIN) {
      errors.price = `${PRICE.MIN.toLocaleString()}円以上の金額を入力してください`;
    } else if (price >= PRICE.MAX) {
      const p = PRICE.MAX - 1;
      errors.price = `${p.toLocaleString()}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(price)) {
      errors.price = '半角数字を入力してください';
    }
  }

  let startPrice: any = PRICE.MIN;
  startPrice = Number(startPrice);

  if (values.price && values.price > PRICE.MIN) {
    startPrice = Number(values.price);
  }

  // 即決価格
  if (values.fixPrice) {
    const fixPrice: any = Number(values.fixPrice);

    if (startPrice >= PRICE.MAX) {
      const p = PRICE.MAX - 1;
      errors.fixPrice = `${p.toLocaleString()}円以下の金額を入力してください`;
    } else if (fixPrice <= startPrice) {
      const p: number = startPrice + 1;
      errors.fixPrice = `${p.toLocaleString()}円以上の金額を入力してください`;
    } else if (fixPrice > PRICE.MAX) {
      errors.fixPrice = `${PRICE.MAX.toLocaleString()}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(fixPrice)) {
      errors.fixPrice = '半角数字を入力してください';
    }
  }

  // 出品期限
  if (!values.closeBidMin) {
    errors.closeBidMin = '出品期限を入力してください';
  } else if (values.closeBidMin) {
    const closeBidMin: any = values.closeBidMin;
    if (!/^[0-9]+$/.test(closeBidMin)) {
      errors.closeBidMin = '半角数字を入力してください';
    } else if (closeBidMin < 1 || closeBidMin > 168) {
      errors.closeBidMin = '1時間 〜 168時間で入力してください';
    }
  }

  // 出品商品説明
  if (!values.explanation) {
    errors.explanation = '出品商品説明を入力してください';
  } else if (values.explanation.length > 1000) {
    errors.explanation = '出品商品説明は1,000文字以内で入力してください';
  }

  // 釣り上げた日が未来の日時になっている場合、エラーをだす
  const date = utils.date.isAfterDate(
    values.fishingYear,
    values.fishingMonth,
    values.fishingDay
  );

  if (date) {
    errors.fishingDate = '正しい日付を入力してください';
  }
  return errors;
};

export const edit = (values: ISale) => {
  const errors: any = {};

  // 商品名
  if (!values.name) {
    errors.name = '商品名を入力してください';
  }

  // 画像
  if (_.isEmpty(values.sellImages)) {
    errors.sellImages = '画像を選択してください';
  }

  // サイズ
  if (!values.size) {
    // errors.size = 'サイズを入力してください';
  } else if (values.size) {
    const size: any = values.size;
    if (!/^[0-9]+$/.test(size)) {
      errors.size = '半角数字を入力してください';
    } else if (/^[0-9]+$/.test(size)) {
      if (size < 1) {
        errors.size = '1以上の数字を入力してください';
      } else if (size > 10000) {
        errors.size = '10,000以下の数字を入力してください';
      }
    }
  }

  // 重量(任意)
  if (values.weight) {
    const weight: any = values.weight;
    if (!/^[0-9]+$/.test(weight)) {
      errors.weight = '半角数字を入力してください';
    } else {
      if (weight < WEIGHT.MIN) {
        errors.weight = `${WEIGHT.MIN}以上の数字を入力してください`;
      } else if (weight > WEIGHT.MAX) {
        errors.weight = `${WEIGHT.MAX}以下の数字を入力してください`;
      }
    }
  }

  // 配送料の負担
  if (!values.shippingExpense) {
    errors.shippingExpense = '配送料の負担を入力してください';
  }

  // 配送までの日数
  if (!values.shippingTiming) {
    errors.shippingTiming = '配送までの日数を入力してください';
  }

  // 配送元の地域
  if (!values.area) {
    errors.area = '発送元の地域を入力してください';
  }

  // 締め方,処理方法
  if (!values.ikejimeMethod) {
    errors.ikejimeMethod = '締め方、処理方法を入力してください';
  }

  // 保管方法
  if (!values.savingMethod) {
    errors.savingMethod = '保管方法を入力してください';
  }

  // 配送方法
  if (!values.deliveryMethod) {
    errors.deliveryMethod = '配送方法を入力してください';
  }

  // 価格
  if (!values.price) {
    errors.price = '出品時の開始価格を入力してください';
  } else {
    const price: any = Number(values.price);

    if (price < PRICE.MIN) {
      errors.price = `${PRICE.MIN}円以上の金額を入力してください`;
    } else if (price >= PRICE.MAX) {
      errors.price = `${PRICE.MAX - 1}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(price)) {
      errors.price = '半角数字を入力してください';
    }
  }

  let startPrice = PRICE.MIN;
  startPrice = Number(startPrice);
  if (values.price && values.price > PRICE.MIN) {
    startPrice = Number(values.price);
  }

  // 即決価格
  if (values.fixPrice) {
    const fixPrice: any = Number(values.fixPrice);

    if (startPrice >= PRICE.MAX) {
      errors.fixPrice = `${PRICE.MAX - 1}円以下の金額を入力してください`;
    } else if (fixPrice <= startPrice) {
      errors.fixPrice = `${startPrice + 1}円以上の金額を入力してください`;
    } else if (fixPrice > PRICE.MAX) {
      errors.fixPrice = `${PRICE.MAX}円以下の金額を入力してください`;
    } else if (!/^[0-9]+$/.test(fixPrice)) {
      errors.fixPrice = '半角数字を入力してください';
    }
  }

  // 出品期限
  if (!values.closeBidMin) {
    errors.closeBidMin = '出品期限を入力してください';
  } else if (values.closeBidMin) {
    const closeBidMin: any = values.closeBidMin;
    if (!/^[0-9]+$/.test(closeBidMin)) {
      errors.closeBidMin = '半角数字を入力してください';
    } else if (closeBidMin < 1 || closeBidMin > 168) {
      errors.closeBidMin = '1時間 〜 168時間で入力してください';
    }
  }

  // 出品商品説明
  if (!values.explanation) {
    errors.explanation = '出品商品説明を入力してください';
  } else if (values.explanation.length > 1000) {
    errors.explanation = '出品商品説明は1,000文字以内で入力してください';
  }

  // 釣り上げた日が未来の日時になっている場合、エラーをだす
  const date = utils.date.isAfterDate(
    values.fishingYear,
    values.fishingMonth,
    values.fishingDay
  );

  if (date) {
    errors.fishingDate = '正しい日付を入力してください';
  }
  return errors;
};
