import {compose, setDisplayName, withHandlers} from 'recompose';

const enhance = compose(
  setDisplayName('GcDropdown'),
  withHandlers({
    handleOnChange: (props) => (event, data) => {
      const {input} = props;
      input.onChange(data.value);
    },
  })
);

export default enhance;
