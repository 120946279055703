import * as React from 'react';
import AfterRoutes from './Parents/AfterRoutes';
import BeforeRoutes from './Parents/BeforeRoutes';

function Routes(props: any) {
  return (
    <div className="a__routes">
      {window.isAuthed ? <AfterRoutes /> : <BeforeRoutes />}
    </div>
  );
}

export default Routes;
