import notify from '@/migration/notification';

import {utils} from '../../../lib/utils';
import {config} from '../../../config';
import {constants} from '../../../constants';
import {ISale} from '@/@types/models/sales';

const paths = config.paths;

export const submit = async (props: any, values: ISale) => {
  try {
    props.isSellLoadingUpdater(true);

    const images = await utils.base64.resizeDroppedImages(values.sellImages);

    const soilInfo = values.soilAdditionalInfo
      ? values.soilAdditionalInfo
          .map((e, i) => (e ? (i + 1).toString() : undefined))
          .filter((f) => f !== undefined)
          .join(',')
      : null;

    const pesticidesInfo = values.pesticidesAdditionalInfo
      ? values.pesticidesAdditionalInfo
          .map((e, i) => {
            if (values.pesticides === null) return undefined;
            switch (values.pesticides) {
              case 2:
                if (i === 0) {
                  return e.toString();
                } else {
                  return undefined;
                }
              case 3:
                if (i !== 0 && e) return (i + 1).toString();
                else {
                  return undefined;
                }
              default:
                return undefined;
            }
          })
          .filter((f) => f !== undefined)
          .join(',')
      : null;

    const body = {
      saleId: props.sales.detail.saleId,
      sellerUserId: props.sales.detail.sellerUserId,
      is_nature: values.isNature,
      start_price: Number(values.price),
      fix_price: Number(values.fixPrice),
      shipping_expense: values.shippingExpense,
      weight: Number(values.weight),
      weight_additional_info: values.weightAdditionalInfo,
      fishing_spot_detail: values.fishingSpotDetail,
      fishing_spot: values.fishingSpot,
      area: values.area,
      images,
      explanation: values.explanation,
      shipping_timing: values.shippingTiming,
      close_bid_min: Number(values.closeBidMin) * 60,
      saving_method: values.savingMethod,
      delivery_method: values.deliveryMethod,
      fishing_day: utils.date.mergeDate(
        values.fishingYear,
        values.fishingMonth,
        values.fishingDay
      ),
      is_vacuum: values.isVacuum,

      // 追加
      name: values.name,
      size: values.size,
      category: values.category,
      rank: values.rank,
      breed: values.breed,
      producer: values.producer,
      grade: values.grade,
      origin: values.origin,
      origin_additional_info: values.originAdditionalInfo,
      harvest_date: utils.date.mergeDate(
        values.fishingYear,
        values.fishingMonth,
        values.fishingDay
      ),
      harvest_timing: values.harvestTiming,
      save_method_seller: values.saveMethodSeller,
      save_method_user: values.saveMethodUser,
      save_method_user_additional_info: values.saveMethodUserAdditionalInfo,
      cultivation_method: values.cultivationMethod,
      soil: values.soil,
      soil_additional_info: soilInfo,
      pesticides: values.pesticides,
      pesticides_additional_info: pesticidesInfo,
      genetic: values.genetic,
      jas: values.jas,
      individual_obligations: values.individualObligations,
      global_gap: values.globalGap ? '認証' : null,
    };

    const pageState = {
      current: props.sales.current,
      searchWords: props.sales.searchWords,
      sortState: props.sales.sortState,
    };

    await props.actions.sales.update(pageState, body, true);

    notify(`商品(${values.name})を更新しました`, 'success', undefined, 5);
    props.isSellLoadingUpdater(false);

    props.history.push(paths.dummySales.main);
  } catch (error) {
    switch ((error as any)?.message) {
      case constants.BAN_WORDS:
        notify('不適切な表現が含まれています。', 'error', undefined, 5);
        break;

      // 管理画面の場合、いる?
      case constants.LIMIT_PER_WEEK:
        notify(
          '月曜～日曜の出品上限数（乱獲防止対策）を超えたため、出品できません。',
          'error',
          undefined,
          5
        );
        break;

      case constants.INAPPROPRIATE_IMAGE:
      case constants.HUMAN_IMAGE:
      case constants.DENIAL_FISH_IMAGE:
      case constants.UNCONTAIN_FISH_IMAGE:
        notify(
          '不適切な画像が含まれているため、出品できません。',
          'error',
          undefined,
          5
        );
        break;

      default:
        notify(
          '出品に失敗しました。しばらく時間をおいて再度お試しください。',
          'error',
          undefined,
          5
        );
        break;
    }
  } finally {
    props.isSellLoadingUpdater(false);
  }
};

export const remove = async (props: any, sale: ISale) => {
  try {
    props.updateLoading(true);

    const pageState = {
      current: props.sales.current,
      searchWords: props.sales.searchWords,
      sortState: props.sales.sortState,
    };

    await props.actions.sales.destroy(pageState, sale, true);

    props.history.push(paths.dummySales.main);
    notify(`商品 (${sale.name}) を削除しました`, 'success');
  } catch (error) {
    notify('出品商品の削除に失敗しました', 'error');
  } finally {
    props.updateLoading(false);
  }
};
