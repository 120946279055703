import {useMount} from 'react-use';

import {api} from '../../../api';

export const useComponentDidMount = (props: any) => {
  useMount(async () => {
    try {
      const id = Number(props.match.params.id);
      const shippingInfo = await api.sales.getShippingInfo({id});
      props.setShippingInfo(shippingInfo);
    } catch (error) {
      console.error(error);
    }
  });
};
