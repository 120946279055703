const local = {
  // GO_ENDPOINT: 'http://localhost:1130/v1/vegetable',
  // SLS_GO_ENDPOINT: 'http://localhost:1130/v1/admin/vegetable',
  // COGNITO_REGION: 'ap-northeast-1',
  // COGNITO_USER_POOL_ID: 'ap-northeast-1_E6B6eBiQo',
  // COGNITO_CLIENT_ID: '4gbs3pmqes9is7punhh81spq31',
  GO_ENDPOINT: 'https://dev.api.vegetablesale.jp/v1/vegetable',
  SLS_GO_ENDPOINT: 'https://dev.api.vegetablesale.jp/v1/admin/vegetable',
  COGNITO_REGION: 'ap-northeast-1',
  COGNITO_USER_POOL_ID: 'ap-northeast-1_YocqsNAau',
  COGNITO_CLIENT_ID: '7a5t31jst0nkjusdhs01cb5sj7',
};

export {local};
