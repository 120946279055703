import * as React from 'react';
import {Button} from 'semantic-ui-react';

import './index.scss';

function DeleteModal(props: any) {
  const {handleCloseModal, sales} = props;

  return (
    <div className="a__sales-delete-modal">
      <div className="b__title c__before-title">
        <h1>出品商品を削除</h1>
      </div>
      <div className="b__messages">
        <p>
          {sales.detail.name}
          を削除しますか？
        </p>
      </div>
      <div className="b__actions">
        <Button
          type="button"
          onClick={handleCloseModal}
          disabled={props.isLoading}
        >
          キャンセル
        </Button>

        <Button
          color="red"
          className="submit"
          onClick={() => {
            props.remove(props, sales.detail);
          }}
          disabled={props.isLoading}
          loading={props.isLoading}
        >
          出品商品を削除する
        </Button>
      </div>
    </div>
  );
}

export default DeleteModal;
