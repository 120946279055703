import produce from 'immer';

export const get = (state: any, payload: any) => {
  const newState = produce(state, (draftState) => {
    draftState.sub = payload.attributes.sub;
    draftState.username = payload.username;
    draftState.email = payload.attributes.email;
    draftState.level = payload.attributes['custom:level'];
  });

  return newState;
};
