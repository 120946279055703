import notify from '@/migration/notification';
import {config} from '../../../config';
import {push} from 'connected-react-router';
import {IUser} from '@/@types/models/users';
import {utils} from '../../../lib/utils';

export const handler = {
  async submitForm(props: any, values: IUser) {
    const {actions} = props;

    try {
      props.setLoading(true);

      const pageState = {
        current: props.users.current,
        searchWords: props.users.searchWords,
        sortState: props.users.sortState,
      };

      await actions.users.update(pageState, values);

      props.setLoading(false);

      notify(
        `ユーザー (${utils.str.replaceTofu(
          values.displayName
        )}) を編集しました`,
        'success'
      );
    } catch (error) {
      props.setLoading(false);
      notify('ユーザーの編集に失敗しました', 'error');
    }
  },
  async destroy(state: any, props: any, user: IUser) {
    const {actions} = props;
    state.setLoading(true);
    const {paths} = config;

    try {
      const pageState = {
        current: props.users.current,
        searchWords: props.users.searchWords,
        sortState: props.users.sortState,
      };

      await actions.users.destroy(pageState, user);

      state.setLoading(false);

      props.history.push(paths.users.main);
      notify(`ユーザー (${user.displayName}) を削除しました`, 'success');
    } catch (error) {
      state.setLoading(false);
      notify('ユーザーの削除に失敗しました', 'error');
    }
  },
};
