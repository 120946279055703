import moment from 'moment';

const news = (values: any) => {
  const errors: any = {};

  if (!values.bodyText) {
    errors.bodyText = ' 内容は必須です';
  }

  if (!values.startDisclose) {
    errors.startDisclose = ' 公開開始日は必須です';
  }

  if (!values.endDisclose) {
    errors.startDisclose = ' 公開終了日は必須です';
  }

  if (!values.startDisclose && !values.endDisclose) {
    errors.startDisclose = '公開開始日、公開終了日は必須です';
  }

  if (values.startDisclose && values.endDisclose) {
    const start = moment(values.startDisclose).format('YYYY-MM-DD');
    const end = moment(values.endDisclose).format('YYYY-MM-DD');

    const isBefore = moment(end).isBefore(start);
    if (isBefore) {
      errors.startDisclose =
        '公開終了日は公開開始日より後の日付を入力してください';
    }
  }

  return errors;
};

export {news};
