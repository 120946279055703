import * as React from 'react';
import {Route} from 'react-router-dom';
import {config} from '@/config';
import {SalesMain, SalesEdit, SalesCreate} from '@/pages/DummySales';

function DummySalesRoutes() {
  return (
    <>
      <Route exact path={config.paths.dummySales.main} component={SalesMain} />
      <Route
        exact
        path={config.paths.dummySales.create}
        component={SalesCreate}
      />
      <Route exact path={config.paths.dummySales.edit} component={SalesEdit} />
    </>
  );
}
export default DummySalesRoutes;
