import * as React from 'react';
import {Route} from 'react-router-dom';
import {config} from '@/config';
import {MailsMain, MailsCreate, MailsEdit} from '@/pages/Mails';

function MailsRoutes() {
  return (
    <>
      <Route exact path={config.paths.mails.main} component={MailsMain} />
      <Route exact path={config.paths.mails.create} component={MailsCreate} />
      <Route exact path={config.paths.mails.edit} component={MailsEdit} />
    </>
  );
}
export default MailsRoutes;
