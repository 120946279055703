import me from './me';
import bans from './bans';
import users from './users';
import sales from './sales';
import news from './news';
import mails from './mails';

const initialState = {
  me,
  bans,
  users,
  sales,
  news,
  mails,
};

export {initialState};
