import {Input} from '@/migration/input';
import React from 'react';
import {Field} from 'redux-form';
import {Button} from 'semantic-ui-react';
import {setState} from './state';
import {paths} from '@/config/paths';

import './index.scss';

/*
  props.createがtrueの場合は、新規フォーム
  props.editがtrueの場合は、編集フォーム
  props.detailがtrueの場合は、閲覧フォーム
*/
function Form(props: any) {
  const {create, handleSubmit, submitForm, loading} = props;
  const state = setState();

  return (
    <div className="a__mypage-form">
      <div className="b__form">
        <form
          className="gc__form"
          onSubmit={handleSubmit(async (values: any) => {
            await submitForm(props, values);
          })}
        >
          <div className="gc__form-card">
            <div className="b__input">
              <div>
                <span className="gc__form-label">ユーザー名</span>
                <Field
                  name="username"
                  dark
                  component={Input}
                  placeholder="ユーザー名"
                  fluid
                  disabled
                  maxLength={30}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '40px',
            }}
          >
            <div>
              <Button
                type="button"
                onClick={() => {
                  props.history.push(paths.users.main);
                }}
                fluid
                disabled={loading}
              >
                前のページに戻る
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
