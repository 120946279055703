import * as React from 'react';
import {enhance} from './enhancer';
import {NewsForm} from './../../../components/News';
import {setState} from './stete';
import {handler} from './handler';

function Create(props: any) {
  const state = setState();

  return (
    <div className="a__news-create">
      <div className="b__title c__after-title">
        <h1>通知を追加する</h1>
      </div>
      <div className="b__form">
        <NewsForm create {...{...props, ...state, ...handler}} />
      </div>
    </div>
  );
}

export default enhance((props: any) => <Create {...props} />);
