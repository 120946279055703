export const handler = {
  updateBans: (state: any, props: any) => {
    return async () => {
      const {actions, bans} = props;
      const {setDisabled} = state;

      setDisabled(true);

      if (bans.pages.active.length !== 0) {
        await actions.bans.updateLoading(true);
        await actions.bans.updateCurrent(1);
        await actions.bans.resetSearchWord();
        await actions.bans.getPagination(1, [], bans.sortState);
        await actions.bans.updateLoading(false);
      }

      setDisabled(false);
    };
  },
  updateSales(state: any, props: any, isDummy?: boolean) {
    return async () => {
      const {actions, sales} = props;
      const {setDisabled} = state;

      setDisabled(true);

      if (sales.pages.active.length !== 0) {
        await actions.sales.updateLoading(true);
        await actions.sales.updateCurrent(1);
        await actions.sales.resetSearchWord();
        await actions.sales.getPagination(1, [], sales.sortState, isDummy);
        await actions.sales.updateLoading(false);
      }

      setDisabled(false);
    };
  },
  updateUsers(state: any, props: any) {
    return async () => {
      const {actions, users} = props;
      const {setDisabled} = state;

      setDisabled(true);

      if (users.pages.active.length !== 0) {
        await actions.users.updateLoading(true);
        await actions.users.updateCurrent(1);
        await actions.users.resetSearchWord();
        await actions.users.getPagination(1, [], users.sortState);
        await actions.users.updateLoading(false);
      }

      setDisabled(false);
    };
  },
};
