import * as React from 'react';
import {Route} from 'react-router-dom';
import {paths} from '@/config/paths';
import {MyPageMain} from '@/pages/MyPage';

function MyPageRoutes() {
  return (
    <>
      <Route exact path={paths.mypage.main} component={MyPageMain} />
    </>
  );
}
export default MyPageRoutes;
