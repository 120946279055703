import $ from 'jquery';

import notify from '@/migration/notification';

export const handler = {
  updateCurrent(state: any, props: any) {
    return async (current: any) => {
      const {actions, mails} = props;
      const {setDisabled} = state;

      setDisabled(true);

      actions.mails.updateCurrent(current);
      await actions.mails.getPagination(
        current,
        mails.searchWords,
        mails.sortState
      );

      setDisabled(false);
    };
  },
  addSearchWord: (state: any, props: any) => async (value: any) => {
    if (value) {
      try {
        props.actions.mails.addSearchWord(value);

        const targetWords = [...props.mails.searchWords, value];
        state.setLoading(true);
        props.reset();

        await props.actions.mails.getPagination(
          1,
          targetWords,
          props.mails.sortState
        );

        // 検索時スクロール位置をトップにする
        $('.rt-tbody').scrollTop(0);

        state.setLoading(false);
      } catch (error) {
        notify('検索に失敗しました。リロードしてください。', 'error');
        state.setLoading(false);
      }
    }
  },
  removeSearchWord: (state: any, props: any) => async (index: any) => {
    const {actions} = props;
    try {
      actions.mails.removeSearchWord(index);

      const targetWords = props.mails.searchWords.filter(
        (word: any, i: number) => {
          return index !== i;
        }
      );
      state.setLoading(true);

      await props.actions.mails.getPagination(
        1,
        targetWords,
        props.mails.sortState
      );

      state.setLoading(false);
    } catch (error) {
      notify('検索に失敗しました。リロードしてください。', 'error');
      state.setLoading(false);
    }
  },
  updateSortState: (state: any, props: any) => async (sortState: any) => {
    const {actions} = props;
    actions.mails.updateSortState(sortState);

    state.setLoading(true);

    await actions.mails.getPagination(1, props.mails.searchWords, sortState);

    state.setLoading(false);
  },
};
