import * as React from 'react';
import {Route} from 'react-router-dom';
import {config} from '@/config';
import {UsersMain, UsersEdit} from '@/pages/Users';

function UsersRoutes() {
  return (
    <>
      <Route exact path={config.paths.users.main} component={UsersMain} />
      <Route exact path={config.paths.users.edit} component={UsersEdit} />
    </>
  );
}
export default UsersRoutes;
