import {useState} from 'react';

export const setState = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  return {
    loading,
    setLoading,
    disabled,
    setDisabled,
  };
};
