import {Auth} from 'aws-amplify';

const auth = {
  getCognitoErrorMessage(code: string) {
    switch (code) {
      case 'UserNotFoundException': {
        return '存在しないメールアドレスです';
      }
      default: {
        return 'メールの送信に失敗しました';
      }
    }
  },
  async getCurrentUser() {
    try {
      const result = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });

      return result;
    } catch (err) {
      console.error(err);
      throw new Error(err as any);
    }
  },
};

export {auth};
