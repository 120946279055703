import * as bans from './bans';
import * as users from './users';
import * as sales from './sales';
import * as news from './news';
import * as mails from './mails';
import * as dummySales from './dummySales';
import * as fees from './fee';
import * as detail from './detail';

const api = {
  bans,
  users,
  sales,
  news,
  mails,
  dummySales,
  fees,
  detail,
};

export {api};
