import {paths} from '../../../../config/paths';
import {signOut} from '@/migration/auth';
import {app} from '../../../../lib/app';

export const handler = {
  signOut: (props: any) => {
    signOut();
    app.location.goToSignIn();
  },
  pushMyPage: (props: any) => {
    props.history.push(paths.mypage.main);
  },
};
