import {useMount} from 'react-use';

import {array} from '@gemcook/utils';

export const useComponentDidMount = (props: any) => {
  useMount(async () => {
    const id = Number(props.match.params.id);
    const active = props.sales.pages.active;

    // if (window.isInitial) {
    await props.actions.sales.getOne(id);
    // } else {
    //   const detail = array.getDetailById(id, active, 'saleId');
    //   props.actions.sales.updateDetail(detail);
    // }
  });
};
