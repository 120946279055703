import React from 'react';

import * as hooks from './hooks';
import * as handlers from './handlers';
import {enhance} from './enhancer';
import {ExhibitionForm} from '../../../components/DummySales';

import './index.scss';

function Create(props: any) {
  hooks.useComponentDidMount(props);

  return (
    <div className="a__dummy-sales-create">
      <div className="b__title">
        <h1>出品情報の入力</h1>
      </div>
      <div className="b__form">
        <ExhibitionForm {...{...props, ...handlers}} />
      </div>
    </div>
  );
}

export default enhance((props: any) => <Create {...props} />);
