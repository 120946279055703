import logger from './useReduxLogger';
import promise from './useReduxPromiseMiddleware';

const env = process.env.NODE_ENV;

let middlewares: any;
if (env === 'development') {
  middlewares = [logger, promise];
} else {
  middlewares = [promise];
}

export {middlewares};
