import {images} from './images';
import {prefecture} from './prefecture';
import * as date from './date';
import * as sales from './sales';
import * as errors from './errors';
import * as auth from './auth';

const constants = {
  images,
  prefecture,
  ...date,
  ...sales,
  ...errors,
  ...auth,
};

export {constants};
