import _ from 'lodash';
import snakeCaseKeys from 'snakecase-keys';
import {app} from '@/lib/app';
import {ISalesSortState, ISale} from '@/@types/models/sales';

const {axios} = app;
const env = app.env.get();
const resources = '/sales';
const limit = 50;

export const getPagination = async (
  current: number,
  searchWords: string[],
  sortState: ISalesSortState,
  isDummy?: boolean
): Promise<any> => {
  try {
    let url;

    // TODO: utils関数化する (makeHitColumnsQuery('name', 'price', 'prefectures'))
    // クエリパラメータのソートを判定
    const sort =
      sortState.order === 'desc'
        ? `%2b${_.snakeCase(sortState.key)}`
        : `%2d${_.snakeCase(sortState.key)}`;

    // クエリパラメータの検索ワードを判定
    if (searchWords.length !== 0) {
      const search = searchWords.join('%20');

      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}&keyword=${search}`;
    } else {
      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}`;
    }

    if (isDummy) {
      url += `&is_dummy=1`;
    }

    const res = await axios.base.get(url);

    const result = {
      pages: res.data.pages,
      totalCount: Number(res.headers['x-total-count']),
      totalPages: Number(res.headers['x-total-pages']),
      current,
    };

    return result;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const getOne = async (id: number) => {
  try {
    const {data} = await axios.base.get(`${resources}/${id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const getShippingInfo = async (id: any) => {
  try {
    const {data} = await axios.auth.get(`/shipping_info?sale_id=${id.id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const update = async (sale: ISale) => {
  const reqbody = {
    ...sale,
  };

  try {
    const {data} = await axios.auth.put(
      `${resources}/${sale.saleId}`,
      snakeCaseKeys(reqbody)
    );
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const destroy = async (sale: ISale) => {
  try {
    const {data} = await axios.auth.delete(
      `${resources}/${sale.saleId}?last_updated_at=${sale.lastUpdatedAt}`
    );
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

/** ダミーの出品魚作成 */
export const create = async (body: ISale) => {
  try {
    const {data} = await axios.auth.post(`${resources}`, body);

    return data;
  } catch (error) {
    throw new Error(error as any);
  }
};
