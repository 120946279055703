import {createActions} from 'redux-actions';
import {api} from './../../api';
import {ISale, ISalesSortState} from '@/@types/models/sales';

const {sales}: any = createActions({
  sales: {
    async getPagination(
      current: number,
      searchWords: string[],
      sortState: ISalesSortState,
      isDummy?: boolean
    ) {
      try {
        const result = await api.sales.getPagination(
          current,
          searchWords,
          sortState,
          isDummy
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateCurrent(current: number) {
      return {current};
    },
    addSearchWord(value: string) {
      return value;
    },
    resetSearchWord() {
      return;
    },
    removeSearchWord(index: number) {
      return index;
    },
    updateSortState(sortState: ISalesSortState) {
      return sortState;
    },
    updateDetail(detail: ISale) {
      return detail;
    },
    async getOne(id: number) {
      try {
        const result = await api.sales.getOne(id);
        const detail = await api.detail.getDetail(id);
        return {...result, ...detail};
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async update(pageState: any, sale: ISale, isDummy?: boolean) {
      try {
        await api.sales.update(sale);
        const result = await api.sales.getPagination(
          pageState.current,
          pageState.searchWords,
          pageState.sortState,
          isDummy
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async destroy(pageState: any, sale: ISale, isDummy?: boolean) {
      try {
        await api.sales.destroy(sale);

        try {
          const result = await api.sales.getPagination(
            pageState.current,
            pageState.searchWords,
            pageState.sortState,
            isDummy
          );
          return result;
        } catch (err) {
          const result = await api.sales.getPagination(
            pageState.current - 1,
            pageState.searchWords,
            pageState.sortState,
            isDummy
          );
          return result;
        }
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateLoading(loading: boolean) {
      return loading;
    },

    /** ダミー出品魚 */
    async create(body: ISale, userId: number) {
      try {
        const result = await api.dummySales.create(body, userId);

        return result;
      } catch (error) {
        throw new Error(error as any);
      }
    },
  },
});

export {sales};
