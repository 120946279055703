import React from 'react';
import {Checkbox} from 'semantic-ui-react';

import './index.scss';

const GcCheckbox = (props: any) => {
  const {meta, input} = props;

  return (
    <div className="a__checkbox">
      <Checkbox
        {...props}
        onChange={(e, {checked}) => input.onChange(checked)}
        checked={!!input.value}
        label={props.label}
      />
      {meta.touched && meta.error && (
        <span className="form__error">{meta.error}</span>
      )}
    </div>
  );
};

export default GcCheckbox;
