const sales = (values: any) => {
  const errors: any = {};
  const ng = ['えび', 'エビ', 'カイ', '海老', '貝'];

  if (!values.name) {
    errors.name = '商品名は必須です';
  }

  if (!values.explanation) {
    errors.explanation = '出品商品説明は必須です';
  } else if (ng.some((word) => values.explanation.indexOf(word) >= 0)) {
    errors.explanation = 'エビ・貝は登録できません';
  }

  return errors;
};

export {sales};
