import * as React from 'react';
import {Button} from 'semantic-ui-react';
import {setState} from './state';

import './index.scss';

function DeleteModal(props: any) {
  const {handleCloseModal, news, destroy} = props;
  const state = setState();

  return (
    <div className="a__news-delete-modal">
      <div className="b__title c__before-title">
        <h1>通知を削除</h1>
      </div>
      <div className="b__messages">
        <p>
          {news.detail.bodyText}
          を削除しますか？
        </p>
      </div>
      <div className="b__actions">
        <Button
          type="button"
          onClick={handleCloseModal}
          disabled={state.loading}
        >
          キャンセル
        </Button>
        <Button
          color="red"
          className="submit"
          onClick={() => {
            destroy(state, props, news.detail);
          }}
          disabled={state.loading}
          loading={state.loading}
        >
          通知を削除する
        </Button>
      </div>
    </div>
  );
}

export default DeleteModal;
