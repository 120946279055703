import {handleActions} from 'redux-actions';
import {ActionType} from 'redux-promise-middleware';
import {actions} from '../actions';
import {repos} from '../repos';
import {AnyAction} from 'redux';
import {initialState} from './../store/initialState';

const news = handleActions(
  {
    [`${actions.news.getPagination}_${ActionType.Fulfilled}`](
      state: any,
      action: AnyAction
    ) {
      return repos.news.getPagination(state, action.payload);
    },
    [`${actions.news.updateCurrent}`](state: any, action: any) {
      return repos.news.updateCurrent(state, action.payload);
    },
    [`${actions.news.addSearchWord}`](state, action) {
      return repos.news.addSearchWord(state, action.payload);
    },
    [`${actions.news.removeSearchWord}`](state, action) {
      return repos.news.removeSearchWord(state, action.payload);
    },
    [`${actions.news.updateSortState}`](state, action) {
      return repos.news.updateSortState(state, action.payload);
    },
    [`${actions.news.updateDetail}`](state, action) {
      return repos.news.updateDetail(state, action.payload);
    },
    [`${actions.news.getOne}_${ActionType.Fulfilled}`](state, action) {
      return repos.news.getOne(state, action.payload);
    },
    [`${actions.news.update}_${ActionType.Fulfilled}`](state, action) {
      return repos.news.getPagination(state, action.payload);
    },
    [`${actions.news.destroy}_${ActionType.Fulfilled}`](state, action) {
      return repos.news.getPagination(state, action.payload);
    },
    [`${actions.news.create}_${ActionType.Fulfilled}`](state, action) {
      return repos.news.getPagination(state, action.payload);
    },
  },
  initialState
);

export {news};
