import * as React from 'react';
import {enhance} from './enhancer';
import {Link} from 'react-router-dom';
import Table from '@/migration/table/Table';
import {Field} from 'redux-form';
import {Search} from '@/migration/search';
import {Icon} from 'semantic-ui-react';
import {MailsTableColumns} from '../../../components/Mails';
import {config} from '../../../config';
import {lifecycle} from './lifecycle';
import {setState} from './state';
import {handler} from './handler';

import './index.scss';

function UsersMain(props: any) {
  const {paths} = config;
  const state = setState();
  lifecycle.componentDidMount(state, props);

  return (
    <div className="a__mails-main">
      <div className="b__title c__after-title">
        <h1 style={{fontFamily: 'UD Shin Go Medium'}}> メール </h1>
      </div>
      <div className="b__sub">
        <div className="b__search">
          <Field
            name="searchWord"
            component={Search}
            placeholder="検索ワードを入力"
            addSearchWord={handler.addSearchWord(state, props)}
            searchWords={props.mails.searchWords}
            removeSearchWord={handler.removeSearchWord(state, props)}
            outline
          />
        </div>
        <div className="b__create">
          <Link to={paths.mails.create}>
            <span>
              <Icon name="add" />
              メールを作成する
            </span>
          </Link>
        </div>
      </div>
      <div className="b__mails">
        <Table
          active={props.mails.pages.active}
          columns={MailsTableColumns(props)}
          current={props.mails.current}
          totalCount={props.mails.totalCount}
          loading={state.loading}
          updateCurrent={handler.updateCurrent(state, props)}
          disabled={state.disabled}
          sortState={props.mails.sortState}
          updateSortState={handler.updateSortState(state, props)}
        />
      </div>
    </div>
  );
}

export default enhance((props: any) => <UsersMain {...props} />);
