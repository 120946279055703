import classNames from 'classnames';
import * as React from 'react';
import {Field} from 'redux-form';
import {Button} from 'semantic-ui-react';
import {config} from '@/config';
import {RequiredIcon, Input, TextArea} from '@/components/Common';
import MailTarget from '../MailTarget';

import './index.scss';

/*
  props.createがtrueの場合は、新規フォーム
  props.editがtrueの場合は、編集フォーム
  props.detailがtrueの場合は、閲覧フォーム
*/
function Form(props: any) {
  const {paths} = config;
  const {create, detail, handleSubmit, submitForm, loading} = props;

  return (
    <div className="a__mails-form">
      <div className="b__form">
        <form
          className="gc__form"
          onSubmit={handleSubmit(async (values: any) => {
            await submitForm(props, values);
          })}
        >
          <div className="gc__form-card">
            <div className="b__input">
              <div
                className={classNames({
                  gc__hidden: create,
                })}
              >
                <span className="gc__form-label">ID</span>
                <Field
                  name="id"
                  dark
                  component={Input}
                  placeholder="ID"
                  fluid
                  disabled
                />
              </div>
              <div>
                <span className="gc__form-label">タイトル</span>
                {!detail ? <RequiredIcon /> : null}
                <Field
                  name="titleText"
                  dark
                  component={Input}
                  placeholder="タイトル"
                  fluid
                  disabled={loading || detail}
                  maxLength={30}
                />
              </div>
              <div>
                <span className="gc__form-label">本文</span>
                {!detail ? <RequiredIcon /> : null}
                <Field
                  name="bodyText"
                  dark
                  component={TextArea}
                  placeholder="本文"
                  fluid
                  disabled={loading || detail}
                  // maxLength={30} 塚本さんの指示があり次第修正する 2019/12/10
                />
              </div>
              <div className="user-condition">
                <span className="gc__form-label">送信先</span>
                {!detail ? <RequiredIcon /> : null}
                <div className="select-condition">
                  <Field
                    name="condition"
                    component={MailTarget}
                    disabled={detail}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '40px',
            }}
          >
            <div
              className={classNames({
                gc__hidden: detail,
              })}
              style={{
                marginBottom: '20px',
              }}
            >
              <Button
                type="submit"
                positive
                className="submit"
                fluid
                disabled={loading}
              >
                メールを送信する
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => {
                  props.history.push(paths.mails.main);
                }}
                fluid
                disabled={loading}
              >
                前のページに戻る
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
