import {useState} from 'react';

export const setState = () => {
  const [openModal, setOpenModal] = useState(false);

  return {
    openModal,
    setOpenModal,
  };
};
