import * as React from 'react';
import {Icon, Dropdown as SemanticDropdown} from 'semantic-ui-react';
import {handler} from './handler';

import './index.scss';

function Dropdown(props: any) {
  return (
    <div className="l__header-dropdown">
      <SemanticDropdown direction="left" text={props.text}>
        <SemanticDropdown.Menu>
          <SemanticDropdown.Header content={`${props.text}さん、こんにちは`} />
          <SemanticDropdown.Divider />
          <SemanticDropdown.Item
            onClick={() => {
              handler.pushMyPage(props);
            }}
          >
            <Icon name="user" className="right floated" />
            マイページ
          </SemanticDropdown.Item>
          <SemanticDropdown.Item
            onClick={() => {
              handler.signOut(props);
            }}
          >
            <Icon name="sign out" className="right floated" />
            サインアウト
          </SemanticDropdown.Item>
        </SemanticDropdown.Menu>
      </SemanticDropdown>
    </div>
  );
}

export default Dropdown;
