const signIn = (values: any) => {
  const errors: any = {};

  let passLen;
  if (values.password !== undefined) {
    passLen = values.password.length;
  }
  let isLowerCase = false;
  let isUpperCase = false;
  let isNum = false;
  let isSymbol = false;
  const reg = new RegExp(/[!"#$%'()+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g);

  if (values.password !== undefined) {
    isLowerCase = values.password.match(/[a-z]/);
    isUpperCase = values.password.match(/[A-Z]/);
    isNum = values.password.match(/[0-9]/);
    isSymbol = reg.test(values.password);
  }

  if (passLen < 6) {
    errors.password = '6文字以上で構成してください';
  } else if (passLen >= 50) {
    errors.password = '50文字以内で構成してください';
  } else if (!isLowerCase || !isUpperCase || !isNum) {
    errors.password = '小文字、大文字、数字を含めてください';
  } else if (isSymbol) {
    errors.password = '英数字以外を含めないでください';
  }

  return errors;
};

export {signIn};
