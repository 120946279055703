import * as React from 'react';
import {Link} from 'react-router-dom';
import {config} from '@/config';

const TableColumns = (props: any) => {
  const {paths} = config;

  return [
    {
      Header: 'ID',
      width: 100,
      sortable: true,
      accessor: 'saleId',
    },
    {
      Header: 'ユーザーID',
      width: 100,
      sortable: true,
      accessor: 'sellerUserId',
    },
    {
      Header: '商品名',
      sortable: true,
      accessor: 'name',
      Cell: (row: any) => {
        return (
          <Link to={`${paths.dummySales.main}/${row.original.saleId}`}>
            {' '}
            {`${row.original.name}`}
          </Link>
        );
      },
    },

    {
      Header: '価格',
      sortable: true,
      accessor: 'displayPrice',
    },
  ];
};

export default TableColumns;
