import {useEffect} from 'react';

export const lifecycle = {
  componentDidMount: async (state: any, props: any) => {
    const {setLoading} = state;
    const {actions, mails} = props;

    const _useEffect = async () => {
      try {
        if (mails.pages.active.length === 0 || window.isInitial) {
          setLoading(true);

          await actions.mails.getPagination(
            mails.current,
            mails.searchWords,
            mails.sortState
          );

          setLoading(false);

          window.isInitial = false;
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    useEffect(() => {
      _useEffect();
    }, []);
  },
};
