import {createActions} from 'redux-actions';
import {api} from './../../api';
import {INewsSortState, INews} from '@/@types/models/news';
import {IUser} from '@/@types/models/users';

const {news}: any = createActions({
  news: {
    async getPagination(
      current: number,
      searchWords: string[],
      sortState: INewsSortState
    ) {
      try {
        const result = await api.news.getPagination(
          current,
          searchWords,
          sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateCurrent(current: number) {
      return {current};
    },
    addSearchWord(value: string) {
      return value;
    },
    removeSearchWord(index: number) {
      return index;
    },
    updateSortState(sortState: INewsSortState) {
      return sortState;
    },
    updateDetail(detail: IUser) {
      return detail;
    },
    async getOne(id: number) {
      try {
        const result = await api.news.getOne(id);
        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async update(pageState: any, news: INews) {
      try {
        await api.news.update(news);
        const result = await api.news.getPagination(
          pageState.current,
          pageState.searchWords,
          pageState.sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async destroy(pageState: any, news: INews) {
      try {
        await api.news.destroy(news);

        try {
          const result = await api.news.getPagination(
            pageState.current,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        } catch (err) {
          const result = await api.news.getPagination(
            pageState.current - 1,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        }
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async create(pageState: any, news: INews) {
      try {
        await api.news.create(news);
        const result = await api.news.getPagination(
          pageState.current,
          pageState.searchWords,
          pageState.sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
  },
});

export {news};
