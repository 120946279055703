import notify from '@/migration/notification';
import {config} from '../../../config';
import {push} from 'connected-react-router';
import {ISale} from '@/@types/models/sales';

export const handler = {
  async submitForm(props: any, values: ISale) {
    const {actions} = props;

    try {
      props.setLoading(true);

      const pageState = {
        current: props.sales.current,
        searchWords: props.sales.searchWords,
        sortState: props.sales.sortState,
      };

      await actions.sales.update(pageState, values);

      props.setLoading(false);

      notify(`出品商品 (${values.name}) を編集しました`, 'success');
    } catch (error) {
      props.setLoading(false);
      notify('出品商品の編集に失敗しました', 'error');
    }
  },
  async destroy(state: any, props: any, sale: ISale) {
    const {paths} = config;
    const {actions} = props;
    state.setLoading(true);

    try {
      const pageState = {
        current: props.sales.current,
        searchWords: props.sales.searchWords,
        sortState: props.sales.sortState,
      };

      await actions.sales.destroy(pageState, sale);

      state.setLoading(false);

      props.history.push(paths.sales.main);
      notify(`出品商品 (${sale.name}) を削除しました`, 'success');
    } catch (error) {
      state.setLoading(false);
      notify('出品商品の削除に失敗しました', 'error');
    }
  },
};
