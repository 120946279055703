import {compose} from 'redux';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {validations} from './../../../lib/validations';
import {withRouter} from 'react-router';

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {},
  };
};

export const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'signIn',
    validate: validations.signIn,
  }),
  withRouter
);
