import * as React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {config} from '@/config';
import {Header, Sidebar} from '@/components/Layouts';
import {enhance} from './enhancer';
import {lifecycle} from './lifecycle';

import './index.scss';

/* Routes */
import BansRoutes from '../../Children/BansRoutes';
import UsersRoutes from '../../Children/UsersRoutes';
import SalesRoutes from '../../Children/SalesRoutes';
import MailsRoutes from '../../Children/MailsRoutes';
import NewsRoutes from '../../Children/NewsRoutes';
import MyPageRoutes from '../../Children/MyPageRoutes';
import DummySalesRoutes from '../../Children/DummySales';

function AfterRoutes(props: any) {
  const {paths} = config;
  lifecycle.componentDidMount(props);

  return (
    <div className="a__after_routes">
      <div className="b__after_routes_sidebar">
        <Sidebar {...props} />
      </div>
      <div className="b__after_routes_main">
        <div className="b__header">
          <Header {...props} />
        </div>
        <div className="b__main">
          <Switch>
            <Route strict path={paths.users.main} component={UsersRoutes} />
            <Route strict path={paths.bans.main} component={BansRoutes} />
            <Route strict path={paths.sales.main} component={SalesRoutes} />
            <Route strict path={paths.mails.main} component={MailsRoutes} />
            <Route strict path={paths.news.main} component={NewsRoutes} />
            <Route
              strict
              path={paths.dummySales.main}
              component={DummySalesRoutes}
            />
            <Route path={paths.mypage.main} component={MyPageRoutes} />
            <Redirect
              to={
                props.me.level === '1'
                  ? paths.users.main
                  : paths.dummySales.main
              }
            />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default enhance((props: any) => <AfterRoutes {...props} />);
