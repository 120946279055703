import React from 'react';
import Radio from '@/migration/radio';

import {GcRadioProps} from './types';

const GcRadio: React.FC<GcRadioProps> = (props) => {
  return <Radio value={props.input.value} {...props} />;
};

export default GcRadio;
