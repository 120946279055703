import * as React from 'react';
import {enhance} from './enhancer';
import {Icon, Image} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import {config} from '@/config';
import {images} from '@/constants/images';
import {setState} from './state';
import {handler} from './handler';

import './index.scss';

function Sidebar(props: any) {
  const state = setState();
  const current = window.location.pathname;
  const {paths} = config;

  return (
    <div className="gc__sidebar">
      <div className="sidebar">
        <ul>
          <li className="logo">
            <Link
              to={
                props.me.level === '1'
                  ? paths.users.main
                  : paths.dummySales.main
              }
              onClick={
                props.me.level === '1'
                  ? handler.updateUsers(state, props)
                  : handler.updateSales(state, props, true)
              }
              className="w__logo"
            >
              <Image src={images.logo} circular />
            </Link>
          </li>

          {props.me.level === '1' ? (
            <>
              <li
                className={classNames({
                  active: _.includes(current, paths.users.main),
                })}
              >
                <Link
                  to={paths.users.main}
                  onClick={handler.updateUsers(state, props)}
                >
                  <Icon name="user" />
                  <span className="title">ユーザー</span>
                </Link>
              </li>
              <li
                className={classNames({
                  active: _.includes(current, paths.bans.main),
                })}
              >
                <Link
                  to={paths.bans.main}
                  onClick={handler.updateBans(state, props)}
                >
                  <Icon name="ban" />
                  <span className="title">禁止ワード</span>
                </Link>
              </li>
              <li
                className={classNames({
                  active: _.includes(current, paths.sales.main),
                })}
              >
                <Link
                  to={paths.sales.main}
                  onClick={handler.updateSales(state, props)}
                >
                  <Icon name="money bill alternate" />
                  <span className="title">出品商品</span>
                </Link>
              </li>
              <li
                className={classNames({
                  active: _.includes(current, paths.mails.main),
                })}
              >
                <Link to={paths.mails.main}>
                  <Icon name="mail" />
                  <span className="title">メール</span>
                </Link>
              </li>
              <li
                className={classNames({
                  active: current === paths.news.main,
                })}
              >
                <Link to={paths.news.main}>
                  <Icon name="newspaper outline" />
                  <span className="title">通知</span>
                </Link>
              </li>
            </>
          ) : null}

          <li
            className={classNames({
              active: _.includes(current, paths.dummySales.main),
            })}
          >
            <Link
              to={paths.dummySales.main}
              onClick={handler.updateSales(state, props, true)}
            >
              <Icon name="money bill alternate" />
              <span className="title">ダミー出品商品</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default enhance((props: any) => <Sidebar {...props} />);
