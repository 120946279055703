import {config} from './../../config';

const location = {
  goToHome() {
    window.location.href = config.paths.users.main;
  },
  goToLimitedHome() {
    window.location.href = config.paths.dummySales.main;
  },
  goToSignIn() {
    window.location.href = config.paths.signIn.main;
  },
};

export {location};
