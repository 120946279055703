import {app} from '@/lib/app';

const {axios} = app;
const resources = '/fee';

export const createFee = async (body: any) => {
  try {
    const {data} = await axios.auth.post(`${resources}`, body);

    return data;
  } catch (error) {
    throw new Error(error as any);
  }
};

export const deleteFeeByUserId = async (userId: number) => {
  try {
    const {data} = await axios.auth.delete(`${resources}/${userId}`);

    return data;
  } catch (error) {
    throw new Error(error as any);
  }
};
