import notify from '@/migration/notification';
import {config} from '../../../config';
import {push} from 'connected-react-router';

export const handler = {
  submitForm: async (props: any, values: any) => {
    const {paths} = config;
    const {actions} = props;
    try {
      props.setLoading(true);

      const pageState = {
        current: props.news.current,
        searchWords: props.news.searchWords,
        sortState: props.news.sortState,
      };

      await actions.news.create(pageState, values);

      props.setLoading(false);

      props.history.push(paths.news.main);

      notify('通知を更新しました', 'success');
    } catch (error) {
      props.setLoading(false);
      notify('通知の更新に失敗しました', 'error');
    }
  },
};
