import {useEffect} from 'react';

export const lifecycle = {
  componentDidMount: async (state: any, props: any) => {
    const {setLoading} = state;
    const {actions, bans} = props;

    useEffect(() => {
      try {
        if (bans.pages.active.length === 0 || window.isInitial) {
          setLoading(true);
          actions.bans.getPagination(
            bans.current,
            bans.searchWords,
            bans.sortState
          );

          setTimeout(() => {
            setLoading(false);
          }, Number(process.env.RESPONSE_WAIT_TIME));
          window.isInitial = false;
        }
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    }, []);
  },
};
