import {handleActions} from 'redux-actions';
import {ActionType} from 'redux-promise-middleware';
import {actions} from '../actions';
import {repos} from '../repos';
import {AnyAction} from 'redux';
import {initialState} from './../store/initialState';

const bans = handleActions(
  {
    [`${actions.bans.getPagination}_${ActionType.Fulfilled}`](
      state: any,
      action: AnyAction
    ) {
      return repos.bans.getPagination(state, action.payload);
    },
    [`${actions.bans.updateCurrent}`](state: any, action: AnyAction) {
      return repos.bans.updateCurrent(state, action.payload);
    },
    [`${actions.bans.updateSortState}`](state: any, action: AnyAction) {
      return repos.bans.updateSortState(state, action.payload);
    },
    [`${actions.bans.addSearchWord}`](state: any, action: AnyAction) {
      return repos.bans.addSearchWord(state, action.payload);
    },
    [`${actions.bans.resetSearchWord}`](state) {
      return repos.bans.resetSearchWord(state);
    },
    [`${actions.bans.removeSearchWord}`](state: any, action: AnyAction) {
      return repos.bans.removeSearchWord(state, action.payload);
    },
    [`${actions.bans.create}_${ActionType.Fulfilled}`](state, action) {
      return repos.bans.getPagination(state, action.payload);
    },
    [`${actions.bans.destroy}_${ActionType.Fulfilled}`](state, action) {
      return repos.bans.getPagination(state, action.payload);
    },
    [`${actions.bans.updateDetail}`](state, action) {
      return repos.bans.updateDetail(state, action.payload);
    },
    [`${actions.bans.getOne}_${ActionType.Fulfilled}`](state, action) {
      return repos.bans.getOne(state, action.payload);
    },
    [`${actions.bans.updateLoading}`](state: any, action: AnyAction) {
      return repos.bans.updateLoading(state, action.payload);
    },
  },
  initialState
);

export {bans};
