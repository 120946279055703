import $ from 'jquery';
import {api} from '../../../api';
import notify from '@/migration/notification';

export const handler = {
  updateCurrent(state: any, props: any) {
    return async (current: any) => {
      const {actions, users} = props;
      const {setDisabled} = state;

      setDisabled(true);

      actions.users.updateCurrent(current);
      await actions.users.getPagination(
        current,
        users.searchWords,
        users.sortState
      );

      // ページネーション更新時テーブル内スクロール位置をトップにする
      $('.rt-tbody').scrollTop(0);

      setDisabled(false);
    };
  },
  addSearchWord: (state: any, props: any) => async (value: any) => {
    if (value) {
      try {
        props.actions.users.addSearchWord(value);

        const targetWords = [...props.users.searchWords, value];
        state.setLoading(true);
        props.reset();

        await props.actions.users.getPagination(
          1,
          targetWords,
          props.users.sortState
        );

        // 検索時スクロール位置をトップにする
        $('.rt-tbody').scrollTop(0);

        state.setLoading(false);
      } catch (error) {
        notify('検索に失敗しました。リロードしてください。', 'error');
        state.setLoading(false);
      }
    }
  },
  removeSearchWord: (state: any, props: any) => async (index: any) => {
    const {actions} = props;
    try {
      actions.users.removeSearchWord(index);

      const targetWords = props.users.searchWords.filter(
        (word: any, i: number) => {
          return index !== i;
        }
      );
      state.setLoading(true);

      await props.actions.users.getPagination(
        1,
        targetWords,
        props.users.sortState
      );

      state.setLoading(false);
    } catch (error) {
      notify('検索に失敗しました。リロードしてください。', 'error');
      state.setLoading(false);
    }
  },
  updateSortState: (state: any, props: any) => async (sortState: any) => {
    const {actions} = props;
    actions.users.updateSortState(sortState);

    state.setLoading(true);

    await actions.users.getPagination(1, props.users.searchWords, sortState);

    state.setLoading(false);
  },

  submit: async (values: any, props: any, state: any) => {
    const {actions, users} = props;

    const fees: Array<{user_id: number; fee: number}> = [];

    for (const [key, value] of Object.entries(values)) {
      if (key.slice(0, 3) !== 'fee') continue;

      const user_id = parseInt(key.slice(3));
      const fee = parseInt(value as string);
      fees.push({user_id, fee});
    }

    const inValidCount = fees.filter(({fee}) => isNaN(fee)).length;
    if (inValidCount > 0) {
      notify('数値以外の値が入力されています。', 'error');
      return;
    }

    try {
      state.setLoading(true);
      await api.fees.createFee(fees);

      await actions.users.getPagination(1, users.searchWords, users.sortState);
      notify('登録に成功しました。', 'success');
    } catch (error) {
      notify('登録に失敗しました。', 'error');
    } finally {
      state.setLoading(false);
    }
  },

  deleteFee: async (userId: number, props: any, state: any) => {
    const {actions, users} = props;

    try {
      state.setLoading(true);
      await api.fees.deleteFeeByUserId(userId);

      await actions.users.getPagination(1, users.searchWords, users.sortState);
      notify('削除に成功しました。', 'success');
    } catch (error) {
      notify('削除に失敗しました。', 'error');
    } finally {
      state.setLoading(false);
    }
  },
};
