import {combineReducers} from 'redux';
import {reducer as form} from 'redux-form';
import {me} from './me';
import {bans} from './bans';
import {users} from './users';
import {sales} from './sales';
import {news} from './news';
import {mails} from './mails';

const createRootReducer = () =>
  combineReducers({
    form,
    me,
    bans,
    users,
    sales,
    news,
    mails,
  });

export {createRootReducer};
