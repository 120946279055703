import {createActions} from 'redux-actions';
import {api} from './../../api';
import {IMailsSortState, IMails} from '@/@types/models/mails';
import {IUser} from '@/@types/models/users';

const {mails}: any = createActions({
  mails: {
    async getPagination(
      current: number,
      searchWords: string[],
      sortState: IMailsSortState
    ) {
      try {
        const result = await api.mails.getPagination(
          current,
          searchWords,
          sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateCurrent(current: number) {
      return {current};
    },
    addSearchWord(value: string) {
      return value;
    },
    removeSearchWord(index: number) {
      return index;
    },
    updateSortState(sortState: IMailsSortState) {
      return sortState;
    },
    updateDetail(detail: IUser) {
      return detail;
    },
    async getOne(id: number) {
      try {
        const result = await api.mails.getOne(id);
        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async update(pageState: any, mails: IMails) {
      try {
        await api.mails.update(mails);
        const result = await api.mails.getPagination(
          pageState.current,
          pageState.searchWords,
          pageState.sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async destroy(pageState: any, mails: IMails) {
      try {
        await api.mails.destroy(mails);

        try {
          const result = await api.mails.getPagination(
            pageState.current,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        } catch (err) {
          const result = await api.mails.getPagination(
            pageState.current - 1,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        }
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async create(pageState: any, mails: IMails) {
      try {
        await api.mails.create(mails);
        const result = await api.mails.getPagination(
          pageState.current,
          pageState.searchWords,
          pageState.sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
  },
});

export {mails};
