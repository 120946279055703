import {DeepPartial} from 'redux';
import {IBansState} from '@/@types/models/bans';

const bans: DeepPartial<IBansState> = {
  pages: {
    active: [],
  },
  detail: {},
  searchWords: [],
  sortState: {
    order: 'asc',
    key: 'id',
  },
  totalCount: 0,
  totalPages: 0,
  current: 1,
};

export default bans;
