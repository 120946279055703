import $ from 'jquery';

import notify from '@/migration/notification';

export const handler = {
  updateCurrent(state: any, props: any) {
    return async (current: any) => {
      const {actions, sales} = props;
      const {setDisabled} = state;

      setDisabled(true);

      actions.sales.updateCurrent(current);
      await actions.sales.getPagination(
        current,
        sales.searchWords,
        sales.sortState,
        true
      );

      setDisabled(false);
    };
  },
  addSearchWord: (state: any, props: any) => async (value: any) => {
    if (value) {
      try {
        props.actions.sales.addSearchWord(value);

        const targetWords = [...props.sales.searchWords, value];
        state.setLoading(true);
        props.reset();

        await props.actions.sales.getPagination(
          1,
          targetWords,
          props.sales.sortState,
          true
        );

        // 検索時スクロール位置をトップにする
        $('.rt-tbody').scrollTop(0);

        state.setLoading(false);
      } catch (error) {
        notify('検索に失敗しました。リロードしてください。', 'error');
        state.setLoading(false);
      }
    }
  },
  removeSearchWord: (state: any, props: any) => async (index: any) => {
    const {actions} = props;
    try {
      actions.sales.removeSearchWord(index);

      const targetWords = props.sales.searchWords.filter(
        (word: any, i: number) => {
          return index !== i;
        }
      );
      state.setLoading(true);

      await props.actions.sales.getPagination(
        1,
        targetWords,
        props.sales.sortState,
        true
      );

      state.setLoading(false);
    } catch (error) {
      notify('検索に失敗しました。リロードしてください。', 'error');
      state.setLoading(false);
    }
  },
  updateSortState: (state: any, props: any) => async (sortState: any) => {
    const {actions} = props;
    actions.sales.updateSortState(sortState);

    state.setLoading(true);

    await actions.sales.getPagination(
      1,
      props.sales.searchWords,
      sortState,
      true
    );

    state.setLoading(false);
  },
};
