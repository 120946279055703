import {signIn} from '@/migration/auth';
import notify from '@/migration/notification';

import {constants} from '../../../constants';
import {app} from './../../../lib/app';

const handlers = {
  handleSignIn: async (values: any) => {
    try {
      const result: any = await signIn(values.email, values.password);
      if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        notify(
          'パスワードリセットが必要です。管理者に問い合わせて下さい',
          'warning'
        );
      } else {
        if (result?.attributes['custom:level'] === constants.level.LIMITED) {
          // ダミー出品
          app.location.goToLimitedHome();
        } else if (
          result?.attributes['custom:level'] === constants.level.FULL
        ) {
          // サインイン成功の場合は、リロード+ホーム画面に遷移する
          app.location.goToHome();
        }
      }
    } catch (err) {
      notify('サインインに失敗しました', 'error');
      console.error(err);
    }
  },
};

export {handlers};
