import {forgotPassword} from '@/migration/auth';
import {paths} from '../../../config/paths';
import {app} from './../../../lib/app';
import notify from '@/migration/notification';

export const handlers = {
  resetPassword: async (props: any, state: any, values: any) => {
    try {
      state.setLoading(true);

      await forgotPassword(values.email);

      state.setLoading(false);

      props.history.push(paths.resetPassword.thank);
    } catch (error) {
      state.setLoading(false);
      const message = app.auth.getCognitoErrorMessage((error as any)?.code);
      notify(message, 'error');
    }
  },
};
