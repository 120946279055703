import React from 'react';
import {TextArea} from 'semantic-ui-react';

import './index.scss';

const SemanticTextArea = (props: any) => {
  const {meta, input} = props;

  return (
    <div className="a__textarea">
      <TextArea
        {...props}
        onChange={(event, field) => input.onChange(field.value)}
        onBlur={() => input.onBlur(input.value)}
        value={input.value}
      />
      {meta.touched && meta.error && (
        <div className="form__error">{meta.error}</div>
      )}
    </div>
  );
};

export default SemanticTextArea;
