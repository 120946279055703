import * as React from 'react';
import {Link} from 'react-router-dom';
import {config} from '@/config';
import {utils} from '@/lib/utils';

const TableColumns = (props: any) => {
  const {paths} = config;
  return [
    {
      Header: 'ID',
      width: 100,
      sortable: true,
      accessor: 'id',
    },
    {
      Header: 'タイトル',
      sortable: false,
      accessor: 'titleText',
      Cell: (row: any) => {
        return (
          <Link to={`${paths.mails.main}/${row.original.id}`}>
            {`${row.original.titleText}`}
          </Link>
        );
      },
    },
    {
      Header: '日付',
      sortable: false,
      accessor: 'createdAt',
      Cell: (row: any) => {
        return <div>{utils.date.convertToTime(row.original.createdAt)}</div>;
      },
    },
  ];
};

export default TableColumns;
