import * as React from 'react';
import {Route} from 'react-router-dom';
import {config} from '@/config';
import {NewsMain, NewsEdit, NewsCreate} from '@/pages/News';

function UsersRoutes() {
  return (
    <>
      <Route exact path={config.paths.news.main} component={NewsMain} />
      <Route exact path={config.paths.news.create} component={NewsCreate} />
      <Route exact path={config.paths.news.edit} component={NewsEdit} />
    </>
  );
}
export default UsersRoutes;
