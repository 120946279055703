import {bans} from './bans';
import {users} from './users';
import {sales} from './sales';
import {news} from './news';
import {mails} from './mails';
import {me} from './me';

const actions = {
  users,
  bans,
  sales,
  news,
  mails,
  me,
};

export {actions};
