import {handleActions} from 'redux-actions';
import {ActionType} from 'redux-promise-middleware';
import {actions} from '../actions';
import {repos} from '../repos';
import {AnyAction} from 'redux';
import {initialState} from '../store/initialState';

const me = handleActions(
  {
    [`${actions.me.get}_${ActionType.Fulfilled}`](
      state: any,
      action: AnyAction
    ) {
      return repos.me.get(state, action.payload);
    },
  },
  initialState
);

export {me};
