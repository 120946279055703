import {useState} from 'react';

export const setState = () => {
  const [disabled, setDisabled] = useState(false);

  return {
    disabled,
    setDisabled,
  };
};
