import {DeepPartial} from 'redux';
import _ from 'lodash';
import {IMeState} from '@/@types/models/me';

const me: DeepPartial<IMeState> = {
  sub: '',
  username: '',
  email: '',
  level: '',
};

export default me;
