import Modal from '@/migration/modal';
import classNames from 'classnames';
import * as React from 'react';
import {Field} from 'redux-form';
import {Button} from 'semantic-ui-react';
import DeleteModal from './../DeleteModal';
import EditModal from './../EditModal';
import {setState} from './state';
import {config} from '@/config';
import {
  RequiredIcon,
  Input,
  TextArea,
  Radio,
  SemanticTextArea,
} from './../../Common';

import './index.scss';

/*
  props.createがtrueの場合は、新規フォーム
  props.editがtrueの場合は、編集フォーム
  props.detailがtrueの場合は、閲覧フォーム
*/
function Form(props: any) {
  const {paths} = config;
  const {
    create,
    edit,
    handleSubmit,
    submitForm,
    loading,
    form,
    selectedForm,
  } = props;
  const state = setState();

  return (
    <div className="a__news-form">
      <div className="b__form">
        <form
          className="gc__form"
          onSubmit={handleSubmit(async (values: any) => {
            if (edit) {
              state.setEditOpenModal(!state.openEditModal);
            } else {
              await submitForm(props, values);
            }
          })}
        >
          <div className="gc__form-card">
            <div className="b__input">
              <div
                className={classNames({
                  gc__hidden: create,
                })}
              >
                <span className="gc__form-label">ID</span>
                <Field
                  name="id"
                  dark
                  component={Input}
                  placeholder="ID"
                  fluid
                  disabled
                />
              </div>
              <div>
                <span className="gc__form-label">通知内容</span>
                <RequiredIcon />
                <Field
                  name="bodyText"
                  dark
                  component={SemanticTextArea}
                  placeholder="通知内容"
                  fluid
                  disabled={loading}
                  maxLength="3000"
                />
              </div>
              <div>
                <span className="gc__form-label">公開期間</span>
                <RequiredIcon />
                <div className="period">
                  <Field
                    name="startDisclose"
                    dark
                    component={Input}
                    type="date"
                    fluid
                    disabled={loading}
                  />
                  <span className="unit">〜</span>
                  <Field
                    name="endDisclose"
                    dark
                    component={Input}
                    type="date"
                    fluid
                    disabled={loading}
                  />
                </div>
              </div>
              <div>
                <span className="gc__form-label">公開設定</span>
                <RequiredIcon />
                <div className="release-setting">
                  <label>
                    <Field
                      name="isDisclosed"
                      component={Radio}
                      type="radio"
                      value={true}
                      formName={form}
                      selectedForm={selectedForm}
                      label="公開する"
                    />
                  </label>
                  <label className="private">
                    <Field
                      name="isDisclosed"
                      component={Radio}
                      type="radio"
                      value={false}
                      formName={form}
                      selectedForm={selectedForm}
                      label="公開しない"
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '40px',
            }}
          >
            <div
              className={classNames({
                gc__hidden: create,
              })}
              style={{
                marginBottom: '20px',
              }}
            >
              <Button positive type="submit" fluid disabled={loading}>
                変更内容を保存する
              </Button>
            </div>
            <div
              className={classNames({
                gc__hidden: create,
              })}
              style={{
                marginBottom: '20px',
              }}
            >
              <Button
                color="red"
                type="button"
                fluid
                onClick={() => {
                  state.setDeleteOpenModal(!state.openDeleteModal);
                }}
                disabled={loading}
              >
                通知を削除する
              </Button>
            </div>
            <div
              className={classNames({
                gc__hidden: edit,
              })}
              style={{
                marginBottom: '20px',
              }}
            >
              <Button
                type="sybmit"
                positive
                className="submit"
                fluid
                disabled={loading}
              >
                通知を作成する
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => {
                  props.history.push(paths.news.main);
                }}
                fluid
                disabled={loading}
              >
                前のページに戻る
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        isModal={state.openDeleteModal}
        size="xs"
        ModalBody={DeleteModal}
        handleCloseModal={() => {
          state.setDeleteOpenModal(!state.openDeleteModal);
        }}
        {...props}
      />
      <Modal
        isModal={state.openEditModal}
        size="xs"
        ModalBody={EditModal}
        handleCloseModal={() => {
          state.setEditOpenModal(!state.openEditModal);
        }}
        {...props}
      />
    </div>
  );
}

export default Form;
