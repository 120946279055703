import Modal from '@/migration/modal';
import * as React from 'react';
import {Field} from 'redux-form';
import {Button} from 'semantic-ui-react';
import DeleteModal from './../DeleteModal';
import {setState} from './state';
import {config} from '@/config';
import {Input, TextArea} from './../../Common';

import './index.scss';

/*
  props.createがtrueの場合は、新規フォーム
  props.editがtrueの場合は、編集フォーム
  props.detailがtrueの場合は、閲覧フォーム
*/
function Form(props: any) {
  const {paths} = config;
  const {edit, handleSubmit, submitForm, loading, shippingInfo} = props;
  const state = setState();

  return (
    <div className="a__sales-form">
      <div className="b__form">
        <form
          className="gc__form"
          onSubmit={handleSubmit(async (values: any) => {
            await submitForm(props, values);
          })}
        >
          <div className="gc__form-card">
            <div className="b__input">
              <div>
                <span className="gc__form-label">ID</span>
                <Field name="saleId" dark component={Input} fluid disabled />
              </div>
              <div>
                <span className="gc__form-label">ユーザーID</span>
                <Field
                  name="sellerUserId"
                  dark
                  component={Input}
                  fluid
                  disabled
                />
              </div>
              <div>
                <span className="gc__form-label">商品名</span>
                <Field
                  name="name"
                  dark
                  component={Input}
                  fluid
                  disabled={loading || props.edit}
                  maxLength={30}
                />
              </div>
              <div>
                <span className="gc__form-label">出品商品説明</span>
                <Field
                  name="explanation"
                  dark
                  component={TextArea}
                  fluid
                  disabled={loading || props.edit}
                  maxLength={1000}
                />
              </div>
              <div className="buyer_shipping_info">
                【送付先】
                <table>
                  <tr>
                    <th>名前：</th>
                    <td>{props.shippingInfo.full_name || '-'}</td>
                  </tr>
                  <tr>
                    <th>郵便番号：</th>
                    <td>{props.shippingInfo.post_number || '-'}</td>
                  </tr>
                  <tr>
                    <th>住所：</th>
                    <td>{props.shippingInfo.address || '-'}</td>
                  </tr>
                  <tr>
                    <th>携帯電話番号：</th>
                    <td>{props.shippingInfo.phone_number || '-'}</td>
                  </tr>
                  <tr>
                    <th>配達指定時間：</th>
                    <td>
                      {(() => {
                        switch (props.shippingInfo.delivery_time) {
                          case 0:
                            return '-';
                          case 1:
                            return '指定しない';
                          case 2:
                            return '午前中';
                          case 3:
                            return '14～16時';
                          case 4:
                            return '16～18時';
                          case 5:
                            return '18～20時';
                          case 6:
                            return '19～21時';
                          default:
                            return '-';
                        }
                      })()}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '40px',
            }}
          >
            <div
              style={{
                marginBottom: '20px',
              }}
            >
              <Button
                color="red"
                type="button"
                fluid
                onClick={() => {
                  state.setOpenModal(!state.openModal);
                }}
                disabled={loading}
              >
                出品商品を削除する
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => {
                  props.history.push(paths.sales.main);
                }}
                fluid
                disabled={loading}
              >
                前のページに戻る
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        isModal={state.openModal}
        size="xs"
        ModalBody={DeleteModal}
        handleCloseModal={() => {
          state.setOpenModal(!state.openModal);
        }}
        {...props}
      />
    </div>
  );
}

export default Form;
