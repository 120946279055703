import {createStore, applyMiddleware, Store, AnyAction} from 'redux';
import {createRootReducer} from '../reducers';
import {middlewares} from './middlewares';
import {composeWithDevTools} from 'redux-devtools-extension';
import {initialState} from './initialState';

export const configureStore = (): Store<any, AnyAction> => {
  const store = createStore(
    createRootReducer(),
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  return store;
};
