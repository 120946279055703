import notify from '@/migration/notification';
import {config} from '../../../config';
import {push} from 'connected-react-router';

export const handler = {
  submitForm: async (props: any, values: any) => {
    const {actions} = props;
    try {
      props.setLoading(true);

      const pageState = {
        current: props.bans.current,
        searchWords: props.bans.searchWords,
        sortState: props.bans.sortState,
      };

      await actions.bans.create(pageState, values);

      props.setLoading(false);

      props.history.push(config.paths.bans.main);

      notify('禁止ワードを更新しました', 'success');
    } catch (error) {
      props.setLoading(false);
      notify('禁止ワードの更新に失敗しました', 'error');
    }
  },
};
