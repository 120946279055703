import * as React from 'react';
import {Route} from 'react-router-dom';
import {config} from '@/config';
import {BansMain, BansCreate, BansEdit} from '@/pages/Bans';

function BansRoutes() {
  return (
    <>
      <Route exact path={config.paths.bans.main} component={BansMain} />
      <Route exact path={config.paths.bans.create} component={BansCreate} />
      <Route exact path={config.paths.bans.edit} component={BansEdit} />
    </>
  );
}
export default BansRoutes;
