import * as React from 'react';
import {Field} from 'redux-form';
import {Button, Image} from 'semantic-ui-react';
import {enhance} from './enhancer';
import {Input} from '@/migration/input';
import {Link} from 'react-router-dom';
import {config} from '../../../config';
import {images} from './../../../constants/images';
import {handlers} from './handlers';

import './index.scss';

function SignIn(props: any) {
  const {signIn, handleSubmit, submitting} = props;
  const {paths} = config;

  return (
    <div className="a__signin">
      <div className="bf__image">
        <Image src={images.logo} size="medium" />
      </div>
      <div className="bf__form">
        <div className="b__title c__before-title">
          <h1>Vegetable Sale 管理画面</h1>
        </div>
        <form
          className="b__form"
          onSubmit={props.handleSubmit(handlers.handleSignIn)}
        >
          <Field
            name="email"
            component={Input}
            type="text"
            placeholder="ユーザー名 か メールアドレス"
            autocomplete="email"
            fluid
            outline
            maxLength={50}
            disabled={submitting}
          />
          <Field
            name="password"
            component={Input}
            type="password"
            placeholder="パスワード"
            maxLength={50}
            fluid
            outline
            autocomplete="current-password"
            disabled={submitting}
          />
          <div className="w__actions">
            <Button
              positive
              type="submit"
              fluid
              disabled={submitting}
              loading={submitting}
            >
              サインイン
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default enhance((props: any) => <SignIn {...props} />);
