import {createLogger} from 'redux-logger';

const loggerSetting = {
  collapsed: true,
  diff: true,
};

const logger = createLogger(loggerSetting);

export default logger;
