import * as React from 'react';
import Dropdown from './Dropdown';
import {utils} from '../../../lib/utils';

import './index.scss';

function Header(props: any) {
  return (
    <div className="gc__header">
      <ul>
        <li>
          <span className="name">
            <Dropdown
              text={utils.str.replaceTofu(props.me.username)}
              {...props}
            />
          </span>
        </li>
      </ul>
    </div>
  );
}

export default Header;
