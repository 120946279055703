import {createActions} from 'redux-actions';
import {api} from './../../api';
import {utils} from '../../lib/utils';
import {IUser, IUsersSortState} from '@/@types/models/users';

const {users}: any = createActions({
  users: {
    async getPagination(
      current: number,
      searchWords: string[],
      sortState: IUsersSortState
    ) {
      try {
        const result = await api.users.getPagination(
          current,
          searchWords,
          sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateCurrent(current: number) {
      return {current};
    },
    addSearchWord(value: string) {
      return value;
    },
    resetSearchWord() {
      return;
    },
    removeSearchWord(index: number) {
      return index;
    },
    updateSortState(sortState: IUsersSortState) {
      return sortState;
    },
    updateDetail(detail: IUser) {
      return detail;
    },
    async getOne(id: number) {
      try {
        const result = await api.users.getOne(id);

        const user = {
          ...result,
          display_name: utils.str.replaceTofu(result.display_name),
        };

        return user;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async update(pageState: any, user: IUser) {
      try {
        await api.users.update(user);
        const result = await api.users.getPagination(
          pageState.current,
          pageState.searchWords,
          pageState.sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async destroy(pageState: any, user: IUser) {
      try {
        await api.users.destroy(user);

        try {
          const result = await api.users.getPagination(
            pageState.current,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        } catch (err) {
          const result = await api.users.getPagination(
            pageState.current - 1,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        }
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateLoading(loading: boolean) {
      return loading;
    },
  },
});

export {users};
