import React from 'react';
import classNames from 'classnames';

import {Props} from './types';

import './index.scss';

const Label = (props: Props) => {
  return (
    <label className="a__label">
      {props.text}
      <div
        className={classNames('badge', {
          required: props.required,
        })}
      >
        <span>{props.required ? '必須' : '任意'}</span>
      </div>
    </label>
  );
};

export default Label;
