export const timing = [
  {
    key: '0',
    value: 0,
    text: '未選択',
  },
  {
    key: '1',
    value: 1,
    text: '直後',
  },
  {
    key: '2',
    value: 2,
    text: '～3時間',
  },
  {
    key: '3',
    value: 3,
    text: '～6時間',
  },
  {
    key: '4',
    value: 4,
    text: '～12時間',
  },
  {
    key: '5',
    value: 5,
    text: '～24時間',
  },
  {
    key: '6',
    value: 6,
    text: '24時間～',
  },
];

export const method = [
  {
    key: '1',
    value: 1,
    text: '内臓処理',
  },
  {
    key: '2',
    value: 2,
    text: '内臓処理、血わた処理',
  },
  {
    key: '3',
    value: 3,
    text: '氷締め（真水）',
  },
  {
    key: '4',
    value: 4,
    text: '氷締め（真水）、内臓処理',
  },
  {
    key: '5',
    value: 5,
    text: '氷締め（真水）、内臓処理、血わた処理',
  },
  {
    key: '6',
    value: 6,
    text: '氷締め（塩水）',
  },
  {
    key: '7',
    value: 7,
    text: '氷締め（塩水）、内臓処理',
  },
  {
    key: '8',
    value: 8,
    text: '氷締め（塩水）、内臓処理、血わた処理',
  },
  {
    key: '9',
    value: 9,
    text: '脳天締め、内臓処理',
  },
  {
    key: '10',
    value: 10,
    text: '脳天締め、内臓処理、血わた処理',
  },
  {
    key: '11',
    value: 11,
    text: '脳天締め→血抜き、内臓処理',
  },
  {
    key: '12',
    value: 12,
    text: '脳天締め→血抜き、内臓処理、血わた処理',
  },
  {
    key: '13',
    value: 13,
    text: '脳天締め→血抜き→神経締め、内臓処理',
  },
  {
    key: '14',
    value: 14,
    text: '脳天締め→血抜き→神経締め、内臓処理、血わた処理',
  },
  {
    key: '15',
    value: 15,
    text: '脳天締め→血抜き→神経締め→水抜き、内臓処理',
  },
  {
    key: '16',
    value: 16,
    text: '脳天締め→血抜き→神経締め→水抜き、内臓処理、血わた処理',
  },
];

export const save = [
  {
    key: '1',
    value: 1,
    text: 'クーラーボックス（保冷）',
  },
  {
    key: '2',
    value: 2,
    text: '冷蔵庫',
  },
  {
    key: '3',
    value: 3,
    text: '冷蔵庫（チルド室）',
  },
  {
    key: '4',
    value: 4,
    text: '冷凍庫',
  },
  {
    key: '5',
    value: 5,
    text: '冷凍庫（家庭用・急速冷凍）',
  },
  {
    key: '6',
    value: 6,
    text: '冷凍庫（家庭用・液体急速凍結・真空）',
  },
  {
    key: '7',
    value: 7,
    text: '冷凍庫（業務用・急速冷凍）',
  },
  {
    key: '8',
    value: 8,
    text: '冷凍庫（業務用・液体急速凍結・真空）',
  },
];

export const expense = [
  {
    key: '1',
    value: 1,
    text: '送料込み（出品者が負担）',
  },
  {
    key: '2',
    value: 2,
    text: '着払い（落札者が負担）',
  },
];

export const delivery = [
  {
    key: '1',
    value: 1,
    text: '常温',
  },
  {
    key: '2',
    value: 2,
    text: '冷蔵',
  },
  {
    key: '3',
    value: 3,
    text: '冷凍',
  },
];

export const shippingTerm = [
  {
    key: '1',
    value: 1,
    text: '落札当日に発送',
  },
  {
    key: '2',
    value: 2,
    text: '落札翌日に発送',
  },
  {
    key: '3',
    value: 3,
    text: '落札2日後に発送',
  },
];

export const area = [
  {
    key: '1',
    value: 1,
    text: '北海道地方',
  },
  {
    key: '2',
    value: 2,
    text: '東北地方',
  },
  {
    key: '3',
    value: 3,
    text: '関東地方',
  },
  {
    key: '4',
    value: 4,
    text: '中部地方',
  },
  {
    key: '5',
    value: 5,
    text: '近畿地方',
  },
  {
    key: '6',
    value: 6,
    text: '中国・四国地方',
  },
  {
    key: '7',
    value: 7,
    text: '九州地方',
  },
  {
    key: '8',
    value: 8,
    text: '沖縄地方',
  },
];

export const closeTerm = [
  {
    key: '1',
    value: 1,
    text: '1時間',
  },
  {
    key: '2',
    value: 3,
    text: '3時間',
  },
  {
    key: '3',
    value: 6,
    text: '6時間',
  },
  {
    key: '4',
    value: 12,
    text: '12時間',
  },
  {
    key: '5',
    value: 24,
    text: '1日',
  },
  {
    key: '6',
    value: 48,
    text: '2日',
  },
  {
    key: '7',
    value: 72,
    text: '3日',
  },
];

// 追加
type selectType = {
  key: string;
  value: number | string | null;
  text: string;
};

export const option: selectType = {
  key: '0',
  value: null,
  text: '未選択',
};

export const size: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '2S',
  },
  {
    key: '2',
    value: 2,
    text: 'S',
  },
  {
    key: '3',
    value: 3,
    text: 'M',
  },
  {
    key: '4',
    value: 4,
    text: '特M',
  },
  {
    key: '5',
    value: 5,
    text: 'L',
  },
  {
    key: '6',
    value: 6,
    text: 'L大',
  },
  {
    key: '7',
    value: 7,
    text: '2L',
  },
  {
    key: '8',
    value: 8,
    text: '3L',
  },
  {
    key: '9',
    value: 9,
    text: '4L',
  },
];

export const saveMethod: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '常温',
  },
  {
    key: '2',
    value: 2,
    text: '保温庫',
  },
  {
    key: '3',
    value: 3,
    text: '冷蔵庫',
  },
  {
    key: '4',
    value: 4,
    text: '冷凍庫',
  },
];

export const harvestTiming: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '早朝',
  },
  {
    key: '2',
    value: 2,
    text: '早',
  },
  {
    key: '3',
    value: 3,
    text: '昼',
  },
  {
    key: '4',
    value: 4,
    text: '夕',
  },
  {
    key: '5',
    value: 5,
    text: '夜',
  },
];

export const cultivationMethod: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '露地',
  },
  {
    key: '2',
    value: 2,
    text: 'ハウス(促成)',
  },
  {
    key: '3',
    value: 3,
    text: '抑制',
  },
  {
    key: '4',
    value: 4,
    text: '水耕',
  },
  {
    key: '5',
    value: 5,
    text: '半',
  },
  {
    key: '6',
    value: 6,
    text: '根域制限',
  },
];

export const grade: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '良々',
  },
  {
    key: '2',
    value: 2,
    text: '良(無印)',
  },
  {
    key: '3',
    value: 3,
    text: '優',
  },
  {
    key: '4',
    value: 4,
    text: '青秀',
  },
  {
    key: '5',
    value: 5,
    text: '赤秀',
  },
  {
    key: '6',
    value: 6,
    text: '特秀',
  },
  {
    key: '7',
    value: 7,
    text: '特選',
  },
];

export const pesticides: selectType[] = [
  // {
  //   key: '1',
  //   value: '1',
  //   text: '通常',
  // },
  {
    key: '2',
    value: 2,
    text: '減農薬',
  },
  {
    key: '3',
    value: 3,
    text: '無農薬',
  },
];

export const pesticidesAdditionalInfo: selectType[] = [
  {
    key: '1',
    value: '1',
    text: '栽培期間中不使用',
  },
  {
    key: '2',
    value: '2',
    text: '全農薬、節減対象農薬のみ',
  },
  {
    key: '3',
    value: '3',
    text: '飛散農薬（有）',
  },
  {
    key: '4',
    value: '4',
    text: '飛散農薬（無）',
  },
  {
    key: '5',
    value: '5',
    text: '残留農薬検査結果ゼロ',
  },
];

export const genetic: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '無',
  },
  {
    key: '2',
    value: 2,
    text: '有',
  },
];

export const soil: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '無肥料',
  },
  {
    key: '2',
    value: 2,
    text: '化学肥料',
  },
  {
    key: '3',
    value: 3,
    text: '有機肥料',
  },
  {
    key: '4',
    value: 4,
    text: '堆肥',
  },
];

export const soilAdditionalInfo: selectType[] = [
  {
    key: '1',
    value: '1',
    text: '家畜ふん',
  },
  {
    key: '2',
    value: '2',
    text: '生ごみ',
  },
  {
    key: '3',
    value: '3',
    text: 'もみがら有機',
  },
  {
    key: '4',
    value: '4',
    text: 'Humanure',
  },
  {
    key: '5',
    value: '5',
    text: '厩肥',
  },
];

export const jas: selectType[] = [
  {
    key: '1',
    value: 1,
    text: '有機',
  },
  {
    key: '2',
    value: 2,
    text: '転換期中有機',
  },
  {
    key: '3',
    value: 3,
    text: '無農薬栽培',
  },
  {
    key: '4',
    value: 4,
    text: '無化学肥料栽培',
  },
  {
    key: '5',
    value: 5,
    text: '減化学肥料',
  },
];
