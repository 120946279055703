import React from 'react';
import {Button} from 'semantic-ui-react';
import {Field, Fields, formValueSelector} from 'redux-form';
import Modal from '@/migration/modal';

import DeleteModal from '../DeleteModal';
import Dropzone from '../Dropzone';
import FishingDate from '../FishingDate';
import {Label, Dropdown, Checkbox} from '@/components/Common';

import {paths} from '@/config/paths';
import {constants} from '@/constants';
import Input from '../Input';
import TextArea from '../TextArea';

import './index.scss';
import {VEGETABLE_CATEGORY, VEGETABLE_DB} from '@/modules/vegetable';

const ExhibitionForm = (props: any) => {
  const {handleSubmit} = props;

  const soil = props.soilValue;
  const pesticides = props.pesticidesValue;
  const category = props.categoryValue;
  const breed = props.breedValue;
  const grade = props.gradeValue;

  console.log('props', VEGETABLE_DB, category);

  return (
    <div className="a__exhibition-form">
      <div className="w__main">
        {props.edit ? null : (
          <div className="w__field dummy-user">
            <Label text="ダミーユーザー" required />
            <Field
              name="dummyUserId"
              options={props.dummyUsers}
              component={Dropdown}
              placeholder="選択してください"
            />
          </div>
        )}

        <form
          onSubmit={handleSubmit(async (values: any) => {
            await props.submit(props, values);
          })}
        >
          {props.edit ? null : (
            <div className="w__field">
              <Field
                name="sellImages"
                component={Dropzone}
                type="image"
                change={props.change}
                users={props.users}
                uploadIsImageLoading={props.uploadIsImageLoading}
                isImageLoading={props.isImageLoading}
              />
            </div>
          )}

          <div className="w__field">
            <Label text="商品名" required />
            <Field
              name="name"
              type="text"
              placeholder=""
              maxLength={50}
              component={Input}
            />
          </div>

          {/* 追加 */}
          {/* カテゴリー */}
          <div className="w__field">
            <Label text="カテゴリー" required />
            <Field
              name="category"
              component={Dropdown}
              fluid
              options={[
                ...Object.keys(VEGETABLE_CATEGORY).map((key, i) => {
                  return {
                    key: i,
                    value: VEGETABLE_CATEGORY[key],
                    name: VEGETABLE_CATEGORY[key],
                    text: VEGETABLE_CATEGORY[key],
                  };
                }),
              ]}
              selection
              placeholder="選択してください"
            />
          </div>

          {/* 品種 */}
          <div className="w__field">
            <Label text="品種" required />
            <Field
              name="breed"
              component={Dropdown}
              fluid
              options={[
                ...VEGETABLE_DB.filter((f) => f.category === category).map(
                  (item, i) => {
                    return {
                      key: i,
                      value: item['name'],
                      name: item['name'],
                      text: item['name'],
                    };
                  }
                ),
              ]}
              selection
              placeholder="選択してください"
            />
          </div>

          {/* 等級 */}
          <div className="w__field">
            <Label text="等級" />
            <Field
              name="grade"
              component={Dropdown}
              fluid
              options={[
                {
                  key: 99999,
                  value: '',
                  name: '',
                  text: '選択してください',
                },
                ...(() => {
                  const data = VEGETABLE_DB.find((f) => f.name === breed);
                  if (!data) return [];

                  return data['grade'].map((val, i) => {
                    return {
                      key: i,
                      value: val,
                      name: val,
                      text: val,
                    };
                  });
                })(),
              ]}
              selection
              placeholder="選択してください"
            />
          </div>

          {/* 階級 */}
          <div className="w__field">
            <Label text="階級" />
            <Field
              name="rank"
              component={Dropdown}
              fluid
              options={[
                {
                  key: 99999,
                  value: '',
                  name: '',
                  text: '選択してください',
                },
                ...(() => {
                  const data = VEGETABLE_DB.find((f) => f.name === breed);
                  if (!data) return [];

                  if (Array.isArray(data['rank']))
                    return data['rank'].map((val, i) => {
                      return {
                        key: i,
                        value: val,
                        name: val,
                        text: val,
                      };
                    });

                  if (!Array.isArray(data['rank'][grade])) {
                    return [];
                  }

                  return data['rank'][grade].map((val, i) => {
                    return {
                      key: i,
                      value: val,
                      name: val,
                      text: val,
                    };
                  });
                })(),
              ]}
              selection
              placeholder="選択してください"
            />
          </div>

          <div className="w__field pb-0">
            <Label text="サイズ（JA規格）" />
            <Field name="size" component={Input} maxLength={50} />
          </div>

          <div className="w__field">
            <Field name="isUnevenness" component={Checkbox} label="不揃い" />
          </div>

          <div className="w__field f pb-0">
            <Label text="重量 (最大10,000,000g)" />
            <Field
              name="weight"
              placeholder="例) 1500"
              label={{basic: true, content: 'g'}}
              labelPosition="right"
              component={Input}
              maxLength={8}
            />
          </div>

          <div className="w__field">
            <Field
              name="weightAdditionalInfo"
              placeholder="備考"
              component={Input}
              maxLength={50}
            />
          </div>

          <div className="w__field">
            <Label text="生産者" />
            <Field
              name="producer"
              type="text"
              placeholder="例) 山田さん"
              maxLength={50}
              component={Input}
            />
          </div>

          <div className="w__field">
            <Label text="産地" />
            <div className="column">
              <Field
                search
                fluid
                name="origin"
                component={Dropdown}
                options={[constants.option, ...constants.prefecture]}
                placeholder="選択してください"
                noResultsMessage="該当する産地はありません"
              />
              <Field
                name="originAdditionalInfo"
                placeholder="詳細ポイント"
                component={Input}
                maxLength={50}
              />
            </div>
          </div>

          <div className="w__field">
            <Label text="収穫日" />
            <Fields
              names={[
                'fishingDate',
                'fishingYear',
                'fishingMonth',
                'fishingDay',
              ]}
              component={FishingDate}
            />
          </div>

          <div className="w__field">
            <Field
              name="isLandAfterFix"
              component={Checkbox}
              label="落札後収穫"
            />
          </div>

          <div className="w__field">
            <Label text="収穫タイミング" />
            <Field
              name="harvestTiming"
              selection
              fluid
              options={[constants.option, ...constants.harvestTiming]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field">
            <Label text="出品者側保管方法" />
            <Field
              name="saveMethodSeller"
              selection
              fluid
              options={[constants.option, ...constants.saveMethod]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field pb-0">
            <Label text="ご家庭での推奨保管方法" />
            <Field
              name="saveMethodUser"
              selection
              fluid
              options={[constants.option, ...constants.saveMethod]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>
          <div className="w__field">
            <Field
              name="saveMethodUserAdditionalInfo"
              placeholder="詳細）日の当たらない涼しい場所に保管してください。"
              component={Input}
              maxLength={50}
            />
          </div>

          <div className="w__field">
            <Label text="栽培方法" />
            <Field
              name="cultivationMethod"
              selection
              fluid
              options={[constants.option, ...constants.cultivationMethod]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field pb-0">
            <Label text="土壌" />
            <Field
              name="soil"
              selection
              fluid
              options={[constants.option, ...constants.soil]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>
          <div className="w__field">
            {soil === 4 && (
              <div className="stack">
                {constants.soilAdditionalInfo.map((item, i) => {
                  return (
                    <Field
                      key={item.key}
                      name={`soilAdditionalInfo[${i}]`}
                      component={Checkbox}
                      label={item.text}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className="w__field pb-0">
            <Label text="農薬" />
            <Field
              name="pesticides"
              selection
              fluid
              options={[constants.option, ...constants.pesticides]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field">
            {pesticides === 2 && (
              <Field
                name="pesticidesAdditionalInfo[0]"
                type="text"
                placeholder="節減対象農薬を節減「当地比〇割減、〇〇地域比○割減）」"
                maxLength={50}
                component={Input}
              />
            )}
            {pesticides === 3 && (
              <div className="stack">
                {constants.pesticidesAdditionalInfo.map((item) => {
                  return (
                    <Field
                      key={item.key}
                      name={`pesticidesAdditionalInfo[${item.key}]`}
                      component={Checkbox}
                      label={item.text}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className="w__field">
            <Label text="遺伝子組み換え" />
            <Field
              name="genetic"
              selection
              fluid
              options={[constants.option, ...constants.genetic]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field">
            <Label text="有機JAS認定" />
            <Field
              name="jas"
              selection
              fluid
              options={[constants.option, ...constants.jas]}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field">
            <Label text="個別的義務表示事項" />
            <Field
              name="individualObligations"
              type="text"
              placeholder="アレルゲン、消費期限又は賞味期限、添加物など"
              maxLength={50}
              component={Input}
            />
          </div>

          <div className="w__field">
            <Label text="グローバルGAP" />
            <Field name="globalGap" component={Checkbox} label="認証" />
          </div>
          {/* 追加ここまで */}

          <div className="w__field">
            <Label text="配送料の負担" required />
            <Field
              name="shippingExpense"
              selection
              fluid
              options={constants.expense}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field">
            <Label text="配送方法" required />
            <Field
              name="deliveryMethod"
              selection
              fluid
              options={constants.delivery}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field">
            <Label text="発送までの日数" required />
            <Field
              name="shippingTiming"
              selection
              fluid
              options={constants.shippingTerm}
              placeholder="選択してください"
              component={Dropdown}
            />
          </div>

          <div className="w__field">
            <Label text="発送元の地域" required />
            <Field
              name="area"
              selection
              fluid
              options={constants.area}
              placeholder="選択してください"
              component={Dropdown}
              noResultsMessage="該当する発送元の地域はありません"
            />
          </div>

          <div className="w__field yen">
            <Label text="出品時の開始価格" required />
            <Field
              name="price"
              placeholder="入力してください"
              label={{basic: true, content: '¥'}}
              labelPosition="left"
              maxLength={8}
              component={Input}
              disabled={props.edit}
              dark={props.edit}
            />
          </div>

          <div className="w__field yen">
            <Label text="即決価格" />
            <Field
              name="fixPrice"
              placeholder="入力してください"
              label={{basic: true, content: '¥'}}
              labelPosition="left"
              maxLength={8}
              component={Input}
              disabled={props.edit}
              dark={props.edit}
            />
          </div>

          <div className="w__field">
            <Label text="出品期限" required />
            <Field
              name="closeBidMin"
              placeholder="選択してください"
              options={constants.closeTerm}
              component={Dropdown}
              noResultsMessage="該当する出品期限はありません"
              type="number"
              disabled={props.edit}
              dark={props.edit}
            />
          </div>

          <div className="w__field">
            <Label text="出品商品説明" required />
            <Field
              rows={5}
              name="explanation"
              component={TextArea}
              maxLength={1001}
            />
          </div>

          <div className="action-buttons">
            <Button
              type="button"
              disabled={props.isSellLoading}
              onClick={() => {
                props.history.push(paths.dummySales.main);
              }}
            >
              キャンセル
            </Button>
            <Button
              positive
              className="submit"
              type="submit"
              loading={props.isSellLoading}
              disabled={props.isSellLoading}
            >
              {props.edit ? '更新する' : '出品する'}
            </Button>
          </div>
        </form>
      </div>

      <div className="right-buttons">
        {props.edit ? (
          <Button
            className="delete"
            type="button"
            fluid
            onClick={() => props.updateIsModal(!props.isModal)}
            disabled={props.isSellLoading}
          >
            削除する
          </Button>
        ) : null}

        <Button
          type="button"
          onClick={() => {
            props.history.push(paths.dummySales.main);
          }}
          fluid
          disabled={props.isSellLoading}
        >
          前のページに戻る
        </Button>
      </div>

      <Modal
        isModal={props.isModal}
        size="xs"
        ModalBody={DeleteModal}
        handleCloseModal={() => {
          props.updateIsModal(!props.isModal);
        }}
        {...props}
      />
    </div>
  );
};

export default ExhibitionForm;
