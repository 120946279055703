import {bans} from './bans';
import {users} from './users';
import {sales} from './sales';
import {news} from './news';
import {mails} from './mails';
import {signIn} from './signIn';
import {resetPassword} from './resetPassword';
import * as dummySales from './dummySales';

const validations = {
  bans,
  users,
  sales,
  news,
  mails,
  signIn,
  resetPassword,
  dummySales,
};

export {validations};
