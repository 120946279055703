import Modal from '@/migration/modal';
import classNames from 'classnames';
import * as React from 'react';
import {Field} from 'redux-form';
import {Button} from 'semantic-ui-react';
import DeleteModal from './../DeleteModal';
import {setState} from './state';
import {config} from '@/config';
import {RequiredIcon, Input} from '@/components/Common';

import './index.scss';

/*
  props.createがtrueの場合は、新規フォーム
  props.editがtrueの場合は、編集フォーム
  props.detailがtrueの場合は、閲覧フォーム
*/
function Form(props: any) {
  const {paths} = config;
  const {create, edit, handleSubmit, submitForm, loading} = props;
  const state = setState();

  return (
    <div className="a__bans-form">
      <div className="b__form">
        <form
          className="gc__form"
          onSubmit={handleSubmit(async (values: any) => {
            await submitForm(props, values);
          })}
        >
          <div className="gc__form-card">
            <div className="b__input">
              <div
                className={classNames({
                  gc__hidden: create,
                })}
              >
                <span className="gc__form-label">ID</span>
                <Field name="id" dark component={Input} fluid disabled />
              </div>
              <div>
                <span className="gc__form-label">禁止ワード名</span>
                {create ? <RequiredIcon /> : null}
                <Field
                  name="wordText"
                  dark
                  component={Input}
                  {...(props.create && {placeholder: '禁止ワード名'})}
                  fluid
                  disabled={loading || edit}
                  maxLength={30}
                />
              </div>
            </div>
            <div className="gc__action-buttons">
              {create && (
                <Button
                  type="button"
                  disabled={loading}
                  onClick={() => {
                    props.history.push(paths.bans.main);
                  }}
                >
                  キャンセル
                </Button>
              )}
              {create ? (
                <Button
                  positive
                  className="submit"
                  type="submit"
                  loading={loading}
                  disabled={loading}
                >
                  追加する
                </Button>
              ) : null}
            </div>
          </div>
          <div
            style={{
              marginLeft: '40px',
            }}
          >
            <div
              className={classNames({
                gc__hidden: create,
              })}
              style={{
                marginBottom: '20px',
              }}
            >
              <Button
                color="red"
                type="button"
                fluid
                onClick={() => {
                  state.setOpenModal(!state.openModal);
                }}
                disabled={loading}
              >
                禁止ワードを削除する
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => {
                  props.history.push(paths.bans.main);
                }}
                fluid
                disabled={loading}
              >
                前のページに戻る
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        isModal={state.openModal}
        size="xs"
        ModalBody={DeleteModal}
        handleCloseModal={() => {
          state.setOpenModal(!state.openModal);
        }}
        {...props}
      />
    </div>
  );
}

export default Form;
