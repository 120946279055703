import Amplify, {Auth} from 'aws-amplify';
import store from 'store';

export const initAmplify = ({
  region,
  userPoolId,
  userPoolWebClientId,
}: {
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
}) => {
  Amplify.configure({
    Auth: {
      region,
      userPoolId,
      userPoolWebClientId,
    },
  });
};

/** セッションの確認 */
export const checkSession = async () => {
  try {
    await Auth.currentSession();

    return true;
  } catch {
    return false;
  }
};

/** サインイン */
export const signIn = (username: string, password: string) => {
  return new Promise((resolve, reject) => {
    Auth.signIn(username, password)
      .then((data) => {
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/** サインアウト */
export const signOut = async () => {
  await Auth.signOut();
  await store.clearAll();
  window.isAuthed = false;
};

/**  */
export const confirmForgotPassword = async ({
  username,
  code,
  newPassword,
}: {
  username: string;
  code: string;
  newPassword: string;
}) => {
  try {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
  } catch (error) {
    throw new Error(error as any);
  }
};

/** */
export const forgotPassword = async (username: string) => {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    throw new Error(error as any);
  }
};

export const getIdToken = async () => {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(error as any);
  }
};
