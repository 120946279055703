import {handleActions} from 'redux-actions';
import {ActionType} from 'redux-promise-middleware';
import {actions} from '../actions';
import {repos} from '../repos';
import {AnyAction} from 'redux';
import {initialState} from './../store/initialState';

const users = handleActions(
  {
    [`${actions.users.getPagination}_${ActionType.Fulfilled}`](
      state: any,
      action: AnyAction
    ) {
      return repos.users.getPagination(state, action.payload);
    },
    [`${actions.users.updateCurrent}`](state: any, action: any) {
      return repos.users.updateCurrent(state, action.payload);
    },
    [`${actions.users.addSearchWord}`](state, action) {
      return repos.users.addSearchWord(state, action.payload);
    },
    [`${actions.users.resetSearchWord}`](state) {
      return repos.users.resetSearchWord(state);
    },
    [`${actions.users.removeSearchWord}`](state, action) {
      return repos.users.removeSearchWord(state, action.payload);
    },
    [`${actions.users.updateSortState}`](state, action) {
      return repos.users.updateSortState(state, action.payload);
    },
    [`${actions.users.updateDetail}`](state, action) {
      return repos.users.updateDetail(state, action.payload);
    },
    [`${actions.users.getOne}_${ActionType.Fulfilled}`](state, action) {
      return repos.users.getOne(state, action.payload);
    },
    [`${actions.users.update}_${ActionType.Fulfilled}`](state, action) {
      return repos.users.getPagination(state, action.payload);
    },
    [`${actions.users.destroy}_${ActionType.Fulfilled}`](state, action) {
      return repos.users.getPagination(state, action.payload);
    },
    [`${actions.users.updateLoading}`](state: any, action: AnyAction) {
      return repos.users.updateLoading(state, action.payload);
    },
  },
  initialState
);

export {users};
