import * as React from 'react';
import {Link} from 'react-router-dom';
import {config} from '@/config';
import {utils} from '@/lib/utils';

const TableColumns = (props: any) => {
  const {paths} = config;

  return [
    {
      Header: 'ID',
      width: 100,
      sortable: true,
      accessor: 'id',
    },
    {
      Header: '通知内容',
      sortable: true,
      accessor: 'bodyText',
      Cell: (row: any) => {
        return (
          <Link to={`${paths.news.main}/${row.original.id}`}>
            {`${row.original.bodyText}`}
          </Link>
        );
      },
    },
    {
      Header: '通知開始',
      sortable: false,
      accessor: 'startDisclose',
      Cell: (row: any) => {
        return <div>{utils.date.convertToUtc(row.original.startDisclose)}</div>;
      },
    },
    {
      Header: '通知終了',
      sortable: false,
      accessor: 'endDisclose',
      Cell: (row: any) => {
        return <div>{utils.date.convertToUtc(row.original.endDisclose)}</div>;
      },
    },
  ];
};

export default TableColumns;
