import * as React from 'react';
import {Route} from 'react-router-dom';
import {SignInMain} from '@/pages/SignIn';
import {config} from '@/config';

function SignInRoutes() {
  return (
    <>
      <Route
        exact
        path={config.paths.signIn.main}
        component={() => <SignInMain />}
      />
    </>
  );
}
export default SignInRoutes;
