import _ from 'lodash';
import moment, {unitOfTime} from 'moment';

const date = {
  convertToDate(targetDate: string) {
    return moment(targetDate).format('YYYY-MM-DD');
  },
  convertToUtc(targetDate: string) {
    return moment(targetDate).format('YYYY-MM-DD');
  },
  convertToTime(targetDate: string) {
    return moment(targetDate).locale('ja').format('YYYY-MM-DD-HH:mm');
  },
  getDate(format?: string) {
    return moment().format(format);
  },
  getSubDate(
    unit: unitOfTime.DurationConstructor,
    amount: string | number,
    format?: string
  ) {
    return moment().subtract(unit, amount).format(format);
  },
  mergeDate(yy: any, mm: any, dd: any, format: string = 'YYYY-MM-DD') {
    return moment([yy, mm - 1, dd]).format(format);
  },
  isAfterDate(yy: any, mm: any, dd: any) {
    return moment().isBefore([yy, mm - 1, dd]);
  },
  format(base?: any, format?: string) {
    const result = moment(base).format(format);

    return result;
  },
  exhibitedDate(start: any, end: any) {
    const startDate: any = new Date(start);
    const endDate: any = new Date(end);

    const diff = endDate - startDate;
    if (_.isNaN(diff)) {
      return '';
    }

    return diff / (1000 * 60 * 60);
  },
};

export {date};
