import {config} from '../../config';

const env = {
  get() {
    let result: {
      GO_ENDPOINT: string;
      SLS_GO_ENDPOINT: string;
      COGNITO_REGION: string;
      COGNITO_USER_POOL_ID: string;
      COGNITO_CLIENT_ID: string;
    } = {
      GO_ENDPOINT: '',
      SLS_GO_ENDPOINT: '',
      COGNITO_REGION: '',
      COGNITO_USER_POOL_ID: '',
      COGNITO_CLIENT_ID: '',
    };

    switch (process.env.REACT_APP_ENVCODE) {
      case 'local': {
        result = config.env.local;
        break;
      }
      case 'dev': {
        result = config.env.dev;
        break;
      }
      case 'stg': {
        result = config.env.stg;
        break;
      }
      case 'prd': {
        result = config.env.prd;
        break;
      }
      default: {
        console.info('no match env...');
        break;
      }
    }

    return result;
  },
};

export {env};
