import React from 'react';
import classNames from 'classnames';
import {Radio as UiRadio} from 'semantic-ui-react';
import enhance from './enhancer';
import * as R from 'ramda';

const Radio = (props: any) => {
  const {
    input,
    outline,
    dark,
    value,
    selectedForm,
    formName,
    label,
    name,
    updateValue,
  } = props;

  const formValue = R.pathOr(
    undefined,
    [formName, 'values', input.name],
    selectedForm
  );

  return (
    <div className="gc__radio">
      <UiRadio
        className={classNames({
          outline: outline,
          dark: dark,
        })}
        input={input}
        label={label}
        name={name}
        checked={formValue === value}
        onChange={updateValue}
      />
    </div>
  );
};

export default enhance((props) => <Radio {...props} />);
