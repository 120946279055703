import React from 'react';
import {TextArea} from '@/migration/textarea';

import {GcTextAreaProps} from './types';

import './index.scss';

const GcTextArea: React.FC<GcTextAreaProps> = (props) => {
  return <TextArea {...props} />;
};

export default GcTextArea;
