import produce from 'immer';
import {makeRemoteActive} from '@/migration/pagination/makeRemoteActive';
import _ from 'lodash';
import camelcaseObjectDeep from 'camelcase-object-deep';
import {utils} from './../../lib/utils';
import {ISalesState} from '@/@types/models/sales';

export const getPagination = (state: ISalesState, payload: any) => {
  const newState = produce(state, (draftState) => {
    draftState.current = payload.current;
    draftState.totalPages = payload.totalPages;
    draftState.totalCount = payload.totalCount;

    const pages = _convertPages(payload.pages);
    draftState.pages = pages;
  });

  return newState;
};

export const updateCurrent = (state: ISalesState, payload: any) => {
  const newState = produce(state, (draftState) => {
    const totalPages = state.totalPages;
    const current = state.current;
    const nextCurrent = payload.current;

    const nextActive = makeRemoteActive(
      state.pages,
      current,
      nextCurrent,
      totalPages
    );

    draftState.pages.active = nextActive;
    draftState.totalPages = totalPages;
    draftState.current = nextCurrent;
    draftState.totalCount = state.totalCount;
  });

  return newState;
};

export const addSearchWord = (state: ISalesState, payload: any) => {
  const word = payload;
  const newState = produce(state, (draftState) => {
    draftState.searchWords = [...state.searchWords, word];
  });

  return newState;
};

export const resetSearchWord = (state: ISalesState) => {
  const newState = produce(state, (draftState) => {
    draftState.searchWords = [];
  });
  return newState;
};

export const removeSearchWord = (state: ISalesState, payload: any) => {
  const index = payload;

  const nextSearchWords = state.searchWords.filter((word, i) => {
    return i !== index;
  });

  const newState = produce(state, (draftState) => {
    draftState.searchWords = [...nextSearchWords];
  });

  return newState;
};

export const updateSortState = (state: ISalesState, payload: any) => {
  const sortState = payload;

  const newState = produce(state, (draftState) => {
    draftState.sortState = sortState;
  });

  return newState;
};

export const updateDetail = (state: ISalesState, payload: any) => {
  const detail = camelcaseObjectDeep(payload);

  const newDetail = {
    ...detail,
  };

  const newState = produce(state, (draftState) => {
    draftState.detail = newDetail;
  });

  return newState;
};

export const getOne = (state: ISalesState, payload: any) => {
  const detail = camelcaseObjectDeep(payload);

  const newDetail = {
    ...detail,
  };

  const newState = produce(state, (draftState) => {
    draftState.detail = newDetail;
  });

  return newState;
};

export const updateLoading = (state: ISalesState, payload: any) => {
  const loading = payload;

  const newState = produce(state, (draftState) => {
    draftState.loading = loading;
  });

  return newState;
};

const _convertPages = (pages: any) => {
  // JSONのキーをスネークからキャメルに変更
  const newPages = camelcaseObjectDeep(pages);

  _.forEach(newPages, (page) => {
    _.forEach(page, (item) => {
      item.miningDate = utils.date.convertToDate(item.miningDate);
    });
  });

  return newPages;
};

/** ダミー出品魚 */
export const create = (state: any) => {
  const newState = produce(state, (draftState) => {
    draftState.detail = {};
  });

  return newState;
};
