import {bindActionCreators, compose} from 'redux';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {actions} from './../../../redux/actions';

const mapStateToProps = (state: any) => {
  return {
    bans: state.bans,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      bans: bindActionCreators(actions.bans, dispatch),
    },
  };
};

const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'searchForm',
  })
);

export {enhance};
