import _ from 'lodash';
import {Base64} from 'js-base64';
import Jimp from 'jimp';

const decode = (str: string) => {
  return Base64.decode(str);
};

const resizeDroppedImages = async (images: any[]) => {
  const promises = images.map(async (image) => {
    let readedImage: any;

    if (_.has(image, 'thumb')) {
      readedImage = await Jimp.read(image.thumb);
    } else {
      return image;
    }

    const base64Image = await readedImage.getBase64Async(
      readedImage._originalMime
    );

    return base64Image;
  });

  const resultImages = await Promise.all(promises);
  return resultImages;
};

export const base64 = {decode, resizeDroppedImages};
