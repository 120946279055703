import notify from '@/migration/notification';
import {config} from '../../../config';
import {push} from 'connected-react-router';
import {INews} from '@/@types/models/news';

export const handler = {
  async submitForm(props: any, values: INews) {
    const {actions, history} = props;

    try {
      props.setLoading(true);

      const pageState = {
        current: props.news.current,
        searchWords: props.news.searchWords,
        sortState: props.news.sortState,
      };

      await actions.news.update(pageState, values);

      props.setLoading(false);

      history.push('/news');
      notify(`通知 (${values.bodyText}) を編集しました`, 'success');
    } catch (error) {
      props.setLoading(false);
      notify('通知の編集に失敗しました', 'error');
    }
  },
  async destroy(state: any, props: any, news: INews) {
    const {paths} = config;
    const {actions} = props;
    state.setLoading(true);

    try {
      const pageState = {
        current: props.news.current,
        searchWords: props.news.searchWords,
        sortState: props.news.sortState,
      };

      await actions.news.destroy(pageState, news);

      state.setLoading(false);

      props.history.push(paths.news.main);
      notify(`通知 (${news.bodyText}) を削除しました`, 'success');
    } catch (error) {
      state.setLoading(false);
      notify('通知の削除に失敗しました', 'error');
    }
  },
};
