const paths = {
  dashboard: {
    main: '/',
  },
  signIn: {
    main: '/signin',
  },
  signUp: {
    main: '/signup',
    thank: '/signup/thank',
  },
  bans: {
    main: '/bans',
    create: '/bans/create',
    edit: '/bans/:id(\\d+)',
  },
  users: {
    main: '/users',
    edit: '/users/:id(\\d+)',
  },
  sales: {
    main: '/sales',
    edit: '/sales/:id(\\d+)',
  },
  news: {
    main: '/news',
    create: '/news/create',
    edit: '/news/:id(\\d+)',
  },
  resetPassword: {
    main: '/reset-password',
    thank: '/reset-password/thank',
    confirm: '/reset-password/confirm',
  },
  terms: {
    main: '/terms',
  },
  mypage: {
    main: '/mypage',
  },
  mails: {
    main: '/mails',
    create: '/mails/create',
    edit: '/mails/:id(\\d+)',
  },
  dummySales: {
    main: '/dummySales',
    create: '/dummySales/create',
    edit: '/dummySales/:id(\\d+)',
  },
};

export {paths};
