import {useState} from 'react';

export const setState = () => {
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [openEditModal, setEditOpenModal] = useState(false);

  return {
    openDeleteModal,
    setDeleteOpenModal,
    openEditModal,
    setEditOpenModal,
  };
};
