import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {configureStore} from './redux';
import {app} from './lib/app';
import Routes from '@/routes';
import {initAmplify, checkSession} from '@/migration/auth';

import './styles/index.scss';

const env = app.env.get();

initAmplify({
  region: env.COGNITO_REGION,
  userPoolId: env.COGNITO_USER_POOL_ID,
  userPoolWebClientId: env.COGNITO_CLIENT_ID,
});

(async function main() {
  try {
    window.isInitial = true;
    window.isAuthed = await checkSession();
  } catch {
    window.isAuthed = false;
  }

  ReactDOM.render(
    <Provider store={configureStore()}>
      <Router>
        <Routes />
      </Router>
    </Provider>,
    window.document.getElementById('___main')
  );
})();

serviceWorker.unregister();
