import Modal from '@/migration/modal';
import * as React from 'react';
import {Field} from 'redux-form';
import {Button} from 'semantic-ui-react';
import DeleteModal from './../DeleteModal';
import {setState} from './state';
import {config} from '@/config';
import {prefecture} from '@/constants/prefecture';
import {Input, TextArea, Dropdown} from '@/components/Common';

import './index.scss';

/*
  props.createがtrueの場合は、新規フォーム
  props.editがtrueの場合は、編集フォーム
  props.detailがtrueの場合は、閲覧フォーム
*/
function Form(props: any) {
  const {paths} = config;
  const {edit, handleSubmit, submitForm, loading} = props;
  const state = setState();

  return (
    <div className="a__users-form">
      <div className="b__form">
        <form
          className="gc__form"
          onSubmit={handleSubmit(async (values: any) => {
            await submitForm(props, values);
          })}
        >
          <div className="gc__form-card">
            <div className="b__input">
              <div>
                <span className="gc__form-label">ID</span>
                <Field
                  name="id"
                  dark
                  component={Input}
                  placeholder="ID"
                  fluid
                  disabled
                />
              </div>
              <div>
                <span className="gc__form-label">ニックネーム</span>
                <Field
                  name="displayName"
                  dark
                  component={Input}
                  placeholder="ニックネーム"
                  fluid
                  disabled
                  maxLength={30}
                />
              </div>
              <div>
                <span className="gc__form-label">メールアドレス</span>
                <Field
                  name="identityCode"
                  dark
                  component={Input}
                  placeholder="メールアドレス"
                  fluid
                  disabled
                />
              </div>
              <div>
                <span className="gc__form-label">都道府県</span>
                <Field
                  name="prefecture"
                  dark
                  component={Dropdown}
                  placeholder="都道府県"
                  fluid
                  options={prefecture}
                  disabled
                  maxLength={30}
                />
              </div>
              <div>
                <span className="gc__form-label">会社名または屋号名</span>
                <Field
                  name="company"
                  dark
                  component={Input}
                  {...(props.create && {placeholder: '会社名または屋号名'})}
                  fluid
                  disabled
                  maxLength={30}
                />
              </div>
              {/* <div>
                <span className="gc__form-label">よく利用する釣船</span>
                <Field
                  name="boat"
                  dark
                  component={Input}
                  {...(props.create && {placeholder: 'よく利用する釣船'})}
                  fluid
                  disabled
                  maxLength={30}
                />
              </div> */}
              <div>
                <span className="gc__form-label">自己紹介文</span>
                <Field
                  name="about"
                  dark
                  component={TextArea}
                  rows="4"
                  {...(props.create && {placeholder: '自己紹介文'})}
                  fluid
                  disabled
                  maxLength={1001}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              marginLeft: '40px',
            }}
          >
            <div
              style={{
                marginBottom: '20px',
              }}
            >
              <Button
                color="red"
                type="button"
                fluid
                onClick={() => {
                  state.setOpenModal(!state.openModal);
                }}
                disabled={loading}
              >
                ユーザーを削除する
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => {
                  props.history.push(paths.users.main);
                }}
                fluid
                disabled={loading}
              >
                前のページに戻る
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        isModal={state.openModal}
        size="xs"
        ModalBody={DeleteModal}
        handleCloseModal={() => {
          state.setOpenModal(!state.openModal);
        }}
        {...props}
      />
    </div>
  );
}

export default Form;
