import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {actions} from '@/redux/actions';
import {withRouter} from 'react-router';

const mapStateToProps = (state: any) => ({
  me: state.me,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      me: bindActionCreators(actions.me, dispatch),
    },
  };
};

export const enhance: any = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);
