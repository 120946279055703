import {useEffect} from 'react';
import _ from 'lodash';
import {utils} from '@/lib/utils';

import {getDetailById} from '@/lib/utils/array';

export const lifecycle = {
  componentDidMount(props: any) {
    const {actions} = props;

    useEffect(() => {
      const id = Number(props.match.params.id);
      const active = props.users.pages.active;

      if (window.isInitial) {
        (async () => {
          await actions.users.getOne(id);
        })();
      } else {
        const detail = getDetailById(id, active);

        const user = {
          ...detail,
          displayName: utils.str.replaceTofu(detail.displayName),
        };
        actions.users.updateDetail(user);
      }
    }, []);
  },
};
