import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {actions} from './../../../redux/actions';

const mapStateToProps = (state: any) => {
  return {
    bans: state.bans,
    sales: state.sales,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      bans: bindActionCreators(actions.bans, dispatch),
      sales: bindActionCreators(actions.sales, dispatch),
      users: bindActionCreators(actions.users, dispatch),
    },
  };
};

const enhance: any = compose(connect(mapStateToProps, mapDispatchToProps));

export {enhance};
