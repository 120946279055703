import * as React from 'react';
import {Route} from 'react-router-dom';
import {
  ResetPasswordMain,
  ResetPasswordThank,
  ResetPasswordConfirm,
} from '@/pages/ResetPassword';
import {paths} from '@/config/paths';

function ResetPasswordRoutes() {
  return (
    <>
      <Route
        exact
        path={paths.resetPassword.main}
        component={ResetPasswordMain}
      />
      <Route
        exact
        path={paths.resetPassword.thank}
        component={ResetPasswordThank}
      />
      <Route
        exact
        path={paths.resetPassword.confirm}
        component={ResetPasswordConfirm}
      />
    </>
  );
}
export default ResetPasswordRoutes;
