import {compose} from 'redux';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router';
import {validations} from './../../../lib/validations';

export const enhance: any = compose(
  withRouter,
  reduxForm({
    form: 'ResetPasswordConfirm',
    validate: validations.resetPassword.confirm,
  })
);
