import {compose, setDisplayName, withHandlers} from 'recompose';
import notify from '@/migration/notification';
import loadImage from 'blueimp-load-image';
import _ from 'lodash';

const MAX_UPLOAD_COUNT = 10;

const enhance: any = compose(
  setDisplayName('FieldSellDropzone'),
  withHandlers({
    drop: (props: any) => async (acceptedFiles: any) => {
      try {
        const tmpDroppedImagesCount = acceptedFiles.length;
        const newDroppedImagesCount =
          props.input.value.length + tmpDroppedImagesCount;

        if (newDroppedImagesCount > MAX_UPLOAD_COUNT) {
          notify(
            `1度にアップロードできる画像は最大${MAX_UPLOAD_COUNT}枚です`,
            'error'
          );
          return;
        }

        const promises = acceptedFiles.map(async (file: any) => {
          const newFile = new Promise((resolve) => {
            loadImage(
              file,
              async (img: any, data: any) => {
                img.toBlob((blob: any) => {
                  const blobUrl = URL.createObjectURL(blob);
                  resolve(
                    Object.assign(file, {
                      original: blobUrl,
                      thumb: blobUrl,
                      cropBoxData: {},
                    })
                  );
                });
              },
              {
                canvas: true,
                orientation: true,
                meta: true,
                contain: true,
                maxWidth: 1000,
              }
            );
          });

          return newFile;
        });

        props.uploadIsImageLoading(true);
        const nextFiles = await Promise.all(promises);

        if (_.isEmpty(props.input.value)) {
          props.change('sellImages', nextFiles);
        } else {
          props.change('sellImages', [...props.input.value, ...nextFiles]);
        }
        props.uploadIsImageLoading(false);
      } catch (err) {
        throw err;
      }
    },

    deleteDroppedImage: (props: any) => (file: any) => {
      const nextDroppedImages = _.reject(props.input.value, (f) => {
        if (f.original === file.original) {
          // メモリーリークを防ぐ為、ローカルのblobUrlを削除
          URL.revokeObjectURL(f.original);
          URL.revokeObjectURL(f.thumb);
        }
        return f.original === file.original;
      });

      props.change('sellImages', nextDroppedImages);
    },
  })
);

export default enhance;
