import {DeepPartial} from 'redux';
import {IUsersState} from '@/@types/models/users';

const users: DeepPartial<IUsersState> = {
  pages: {
    active: [],
  },
  detail: {},
  searchWords: [],
  sortState: {
    order: 'asc',
    key: 'id',
  },
  totalCount: 0,
  totalPages: 0,
  current: 1,
};

export default users;
