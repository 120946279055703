import {handleActions} from 'redux-actions';
import {ActionType} from 'redux-promise-middleware';
import {actions} from '../actions';
import {repos} from '../repos';
import {AnyAction} from 'redux';
import {initialState} from './../store/initialState';

const mails = handleActions(
  {
    [`${actions.mails.getPagination}_${ActionType.Fulfilled}`](
      state: any,
      action: AnyAction
    ) {
      return repos.mails.getPagination(state, action.payload);
    },
    [`${actions.mails.updateCurrent}`](state: any, action: any) {
      return repos.mails.updateCurrent(state, action.payload);
    },
    [`${actions.mails.addSearchWord}`](state, action) {
      return repos.mails.addSearchWord(state, action.payload);
    },
    [`${actions.mails.removeSearchWord}`](state, action) {
      return repos.mails.removeSearchWord(state, action.payload);
    },
    [`${actions.mails.updateSortState}`](state, action) {
      return repos.mails.updateSortState(state, action.payload);
    },
    [`${actions.mails.updateDetail}`](state, action) {
      return repos.mails.updateDetail(state, action.payload);
    },
    [`${actions.mails.getOne}_${ActionType.Fulfilled}`](state, action) {
      return repos.mails.getOne(state, action.payload);
    },
    [`${actions.mails.update}_${ActionType.Fulfilled}`](state, action) {
      return repos.mails.getPagination(state, action.payload);
    },
    [`${actions.mails.destroy}_${ActionType.Fulfilled}`](state, action) {
      return repos.mails.getPagination(state, action.payload);
    },
    [`${actions.mails.create}_${ActionType.Fulfilled}`](state, action) {
      return repos.mails.getPagination(state, action.payload);
    },
  },
  initialState
);

export {mails};
