const bans = (values: any) => {
  const errors: any = {};

  if (!values.wordText) {
    errors.wordText = '禁止ワード名は必須です';
  }

  return errors;
};

export {bans};
