import {createActions} from 'redux-actions';
import {api} from './../../api';
import {IBansSortState, IBan} from '@/@types/models/bans';

const {bans}: any = createActions({
  bans: {
    async getPagination(
      current: number,
      searchWords: string[],
      sortState: IBansSortState
    ) {
      try {
        const result = await api.bans.getPagination(
          current,
          searchWords,
          sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateCurrent: (current: number = 1) => {
      return {current};
    },
    addSearchWord: (value: string) => {
      return value;
    },
    resetSearchWord() {
      return;
    },
    removeSearchWord: (index: number) => {
      return index;
    },
    updateSortState: (sortState: IBansSortState) => {
      return sortState;
    },
    async create(pageState: any, ban: IBan) {
      try {
        await api.bans.create(ban);
        const result = await api.bans.getPagination(
          pageState.current,
          pageState.searchWords,
          pageState.sortState
        );

        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    async destroy(pageState: any, ban: IBan) {
      try {
        await api.bans.destroy(ban);

        try {
          const result = await api.bans.getPagination(
            pageState.current,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        } catch (err) {
          const result = await api.bans.getPagination(
            pageState.current - 1,
            pageState.searchWords,
            pageState.sortState
          );
          return result;
        }
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateDetail(detail: IBan) {
      return detail;
    },
    async getOne(id: number) {
      try {
        const result = await api.bans.getOne(id);
        return result;
      } catch (err) {
        throw new Error(err as any);
      }
    },
    updateLoading(loading: boolean) {
      return loading;
    },
  },
});

export {bans};
