import $ from 'jquery';

import notify from '@/migration/notification';

export const handler = {
  updateCurrent(state: any, props: any) {
    return async (current: any) => {
      const {actions, news} = props;
      const {setDisabled} = state;

      setDisabled(true);

      actions.news.updateCurrent(current);
      await actions.news.getPagination(
        current,
        news.searchWords,
        news.sortState
      );

      setDisabled(false);
    };
  },
  addSearchWord: (state: any, props: any) => async (value: any) => {
    if (value) {
      try {
        props.actions.news.addSearchWord(value);

        const targetWords = [...props.news.searchWords, value];
        state.setLoading(true);
        props.reset();

        await props.actions.news.getPagination(
          1,
          targetWords,
          props.news.sortState
        );

        // 検索時スクロール位置をトップにする
        $('.rt-tbody').scrollTop(0);

        state.setLoading(false);
      } catch (error) {
        notify('検索に失敗しました。リロードしてください。', 'error');
        state.setLoading(false);
      }
    }
  },
  removeSearchWord: (state: any, props: any) => async (index: any) => {
    const {actions} = props;
    try {
      actions.news.removeSearchWord(index);

      const targetWords = props.news.searchWords.filter(
        (word: any, i: number) => {
          return index !== i;
        }
      );
      state.setLoading(true);

      await props.actions.news.getPagination(
        1,
        targetWords,
        props.news.sortState
      );

      state.setLoading(false);
    } catch (error) {
      notify('検索に失敗しました。リロードしてください。', 'error');
      state.setLoading(false);
    }
  },
  updateSortState: (state: any, props: any) => async (sortState: any) => {
    const {actions} = props;
    actions.news.updateSortState(sortState);

    state.setLoading(true);

    await actions.news.getPagination(1, props.news.searchWords, sortState);

    state.setLoading(false);
  },
};
