import {useMount} from 'react-use';

import {api} from '../../../api';

export const useComponentDidMount = (props: any) => {
  useMount(async () => {
    try {
      const dummyUsers = await api.users.getDummies({is_dummy: 1});
      const _dummyUsers = dummyUsers.map((d: any, index: number) => {
        return {
          key: index,
          value: d.id,
          text: d.name,
        };
      });
      props.setDummyUsers(_dummyUsers);
    } catch (error) {
      console.error(error);
    }
  });
};
