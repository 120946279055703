import {confirmForgotPassword} from '@/migration/auth';
import {paths} from '../../../config/paths';
import {utils} from '../../../lib/utils';
import notify from '@/migration/notification';

export const handlers = {
  confirm: async (props: any, state: any, values: any) => {
    try {
      const {history} = props;
      const qs = window.location.search;
      const parsedQs = utils.qs.parse(qs);

      const _username = String(parsedQs.username);

      const username = utils.base64.decode(_username);

      state.setLoading = true;
      await confirmForgotPassword({
        username,
        code: values.code,
        newPassword: values.password,
      });
      state.setLoading = false;

      notify(
        '新しいパスワードの設定に成功しました。サインインして下さい。',
        'success'
      );

      history.push(paths.signIn.main);
    } catch (error) {
      state.setLoading = false;
      console.error(error);
      notify('認証コードに間違いがあります', 'error');
    }
  },
};
