import React, {useState, useEffect} from 'react';
import {useDropzone} from 'react-dropzone';

import enhance from './enhance';
import {Label} from '../../Common';
import ThumbContainer from '../ThumbContainer';

import {styles} from './styles';
import './index.scss';

const Dropzone = (props: any) => {
  const [style, setStyle] = useState({});
  const onDrop = props.drop;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/*',
    onDrop,
  });

  useEffect(() => {
    setStyle({
      ...styles.baseStyle,
      ...(isDragActive ? styles.activeStyle : {}),
      ...(isDragAccept ? styles.acceptStyle : {}),
      ...(isDragReject ? styles.rejectStyle : {}),
    });
  }, [isDragActive, isDragReject]);

  return (
    <div className="a__dropzone">
      <div>
        <Label text="出品画像" required />
      </div>

      <section>
        <div className="image_field_wrapper" {...getRootProps({style})}>
          <input {...getInputProps()} />
          <div className="image_field_base_wrapper">
            <p className="image_field_text">
              ドラッグ＆ドロップ
              <br />
              またはクリックで画像を追加（最大10枚）
              <br />
            </p>
          </div>
        </div>
      </section>
      <p style={{fontSize: '12px', fontFamily: 'sans-serif'}}>
        ※アップロード可能拡張子 .apng, .bmp, .gif, .ico, .jpg, .jpeg, .png, svg,
        .tiff, .webp
      </p>

      <ThumbContainer droppedImages={props.input.value} {...props} />
      {props.meta.touched && props.meta.error && (
        <span className="form_error">{props.meta.error}</span>
      )}
    </div>
  );
};

export default enhance((props: any) => <Dropzone {...props} />);
