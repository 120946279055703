import * as React from 'react';

export default function RequiredIcon(props: Object) {
  return (
    <div
      style={{
        position: 'relative',
        bottom: '2px',
        display: 'inline-flex',
        width: '32px',
        height: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f77272',
        borderRadius: '2px',
        marginLeft: '12px',
      }}
      className="a__required-icon"
    >
      <span
        style={{
          color: '#fff',
          fontSize: '10px',
          lineHeight: 1,
          textAlign: 'center',
        }}
      >
        必須
      </span>
    </div>
  );
}
