import * as React from 'react';
import {Link} from 'react-router-dom';
import {config} from '@/config';

const TableColumns = (props: any) => {
  const {paths} = config;
  return [
    {
      Header: 'ID',
      width: 100,
      sortable: true,
      accessor: 'id',
    },
    {
      Header: '禁止ワード',
      sortable: true,
      accessor: 'wordText',
      Cell: (row: any) => {
        return (
          <Link to={`${paths.bans.main}/${row.original.id}`}>
            {' '}
            {`${row.original.wordText}`}
          </Link>
        );
      },
    },
  ];
};

export default TableColumns;
