import {compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

const mapStateToProps = (state: any) => ({
  bans: state.bans,
});

const mapDispatchToProps = () => {
  return {
    actions: {},
  };
};

export const enhance: any = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);
