import notify from '@/migration/notification';
import {config} from '../../../config';
import {push} from 'connected-react-router';
import {IBan} from '@/@types/models/bans';

export const handler = {
  async destroy(state: any, props: any, ban: IBan) {
    const {actions} = props;
    state.setLoading(true);

    try {
      const pageState = {
        current: props.bans.current,
        searchWords: props.bans.searchWords,
        sortState: props.bans.sortState,
      };

      await actions.bans.destroy(pageState, ban);

      state.setLoading(false);

      props.history.push(config.paths.bans.main);
      notify(`禁止ワード (${ban.wordText}) を削除しました`, 'success');
    } catch (error) {
      state.setLoading(false);
      notify('禁止ワードの削除に失敗しました', 'error');
    }
  },
};
