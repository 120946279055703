import notify from '@/migration/notification';
import {config} from '../../../config';
import {push} from 'connected-react-router';

export const handler = {
  submitForm: async (props: any, values: any) => {
    const {paths} = config;
    const {actions} = props;
    try {
      props.setLoading(true);

      const pageState = {
        current: props.mails.current,
        searchWords: props.mails.searchWords,
        sortState: props.mails.sortState,
      };

      const params = {
        ...values,
        condition: values.condition ? 1 : 0,
      };

      await actions.mails.create(pageState, params);

      props.setLoading(false);

      props.history.push(paths.mails.main);

      notify('メールを送信しました', 'success');
    } catch (error) {
      props.setLoading(false);
      notify('メールの送信に失敗しました', 'error');
    }
  },
};
