import React from 'react';
import classNames from 'classnames';
import Dropdown from '@/migration/dropdown';

import {GcDropdownProps} from './types';

import './index.scss';

const GcDropdown: React.FC<GcDropdownProps> = (props) => {
  return (
    <div
      className={classNames('a__gc-dropdown', {
        disabled: props.disabled,
      })}
    >
      <Dropdown {...props} />
    </div>
  );
};

export default GcDropdown;
