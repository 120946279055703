import {useState} from 'react';

export const setState = () => {
  const [loading, setLoading] = useState(false);

  return {
    loading,
    setLoading,
  };
};
