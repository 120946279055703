import _ from 'lodash';
import snakeCaseKeys from 'snakecase-keys';
import {app} from '@/lib/app';
import {utils} from '../lib/utils';
import {IUsersSortState, IUser} from '@/@types/models/users';

const {axios} = app;
const env = app.env.get();
const resources = '/users';
const limit = 50;

export const getPagination = async (
  current: number,
  searchWords: string[],
  sortState: IUsersSortState
): Promise<any> => {
  try {
    let url;

    // TODO: utils関数化する (makeHitColumnsQuery('name', 'price', 'prefectures'))
    // クエリパラメータのソートを判定
    const sort =
      sortState.order === 'desc'
        ? `%2b${_.snakeCase(sortState.key)}`
        : `%2d${_.snakeCase(sortState.key)}`;

    // クエリパラメータの検索ワードを判定
    if (searchWords.length !== 0) {
      const search = searchWords.join('%20');

      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}&keyword=${search}`;
    } else {
      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}`;
    }

    const res = await axios.auth.get(url);

    const result = {
      pages: res.data.pages,
      totalCount: Number(res.headers['x-total-count']),
      totalPages: Number(res.headers['x-total-pages']),
      current,
    };

    return result;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const getOne = async (id: number) => {
  try {
    const {data} = await axios.auth.get(`${resources}/${id}`);

    const user = {
      ...data,
      display_name: utils.str.replaceTofu(data.display_name),
    };

    return user;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const update = async (user: IUser) => {
  const reqbody = {
    ...user,
  };

  try {
    const {data} = await axios.auth.put(
      `${resources}/${user.id}`,
      snakeCaseKeys(reqbody)
    );
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const destroy = async (user: IUser) => {
  try {
    const {data} = await axios.auth.delete(`${resources}/${user.id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

// ダミーユーザーの取得
export const getDummies = async (qp: any) => {
  try {
    const {data} = await axios.auth.get(`/users_all`, {
      params: qp,
    });
    return data;
  } catch (error) {
    throw new Error(error as any);
  }
};
