import React from 'react';
import {Link} from 'react-router-dom';
import {Field} from 'redux-form';
import {Button} from 'semantic-ui-react';
import {Input} from '@/migration/input';
import {enhance} from './enhancer';
import {handlers} from './handlers';
import {setState} from './state';
import {paths} from './../../../config/paths';

import './index.scss';

function Confirm(props: any) {
  const {confirm, handleSubmit, submitting} = props;
  const state = setState();

  return (
    <div className="a__reset_password_confirm">
      <div className="bf__form">
        <div className="b__title c__before-title">
          <h1>パスワードリセット</h1>
        </div>
        <div className="b__messages">
          メールに記載されている6桁の認証コードと新しいパスワードを入力して、
          <br />
          パスワードの設定を完了させてください。
          <br />
          パスワードの設定の必要が無い場合は&nbsp;
          <Link to={paths.signIn.main}>サインイン</Link>
          &nbsp;してください。
        </div>
        <form
          className="b__form"
          onSubmit={handleSubmit(async (values: any) => {
            await handlers.confirm(props, state, values);
          })}
        >
          <Field
            name="code"
            component={Input}
            type="text"
            placeholder="認証コード"
            fluid
            outline
            disabled={submitting}
          />
          <Field
            name="password"
            component={Input}
            type="password"
            placeholder="新しいパスワード"
            outline
            fluid
            disabled={submitting}
          />
          <Field
            name="confirmPassword"
            component={Input}
            type="password"
            placeholder="新しいパスワード (確認用)"
            outline
            fluid
            disabled={submitting}
          />
          <div className="w__actions">
            <Button
              positive
              fluid
              type="submit"
              disabled={submitting}
              loading={submitting}
            >
              メールを送信する
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default enhance((props: any) => <Confirm {...props} />);
