import {withState} from 'recompose';
import {bindActionCreators, compose} from 'redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import notify from '@/migration/notification';

import {actions} from './../../../redux/actions';
import {utils} from '../../../lib/utils';
import {validations} from '../../../lib/validations';

// 追加
const selector = formValueSelector('dummySalesCreate');

const mapStateToProps = (state: any) => ({
  sales: state.sales,
  // 追加
  soilValue: selector(state, 'soil'),
  pesticidesValue: selector(state, 'pesticides'),

  categoryValue: selector(state, 'category'),
  breedValue: selector(state, 'breed'),
  gradeValue: selector(state, 'grade'),
  rankValue: selector(state, 'rank'),

  initialValues: {
    isNature: true,
    fishingYear: utils.date.getDate('YYYY'),
    fishingMonth: utils.date.getDate('M'),
    fishingDay: utils.date.getDate('D'),
  },
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      sales: bindActionCreators(actions.sales, dispatch),
    },
  };
};

export const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'dummySalesCreate',
    validate: validations.dummySales.create,
    enableReinitialize: true,
    onSubmitFail() {
      notify('入力内容をご確認ください。', 'error', undefined, 5);
    },
  }),
  withRouter,
  withState('isImageLoading', 'uploadIsImageLoading', false),
  withState('isSellLoading', 'isSellLoadingUpdater', false),
  withState('dummyUsers', 'setDummyUsers', [])
);
