import * as React from 'react';
import {Link} from 'react-router-dom';
import {paths} from './../../../config/paths';

import './index.scss';

function Thank() {
  return (
    <div className="a__reset_password_thank">
      <div className="bf__form">
        <div className="b__title c__before-title">
          <h1>認証コードをお送りしました</h1>
        </div>
        <div className="b__messages">
          <div>
            入力されたメールアドレス宛にパスワードリセット用の認証コードをお送りしました。
            <br />
            メールのリンクから認証コードと新しいパスワードを入力して、パスワードリセットを完了させて下さい。
            <br />
          </div>
          <br />
          <div>
            <Link to={paths.signIn.main}>サインインページに戻る</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Thank;
