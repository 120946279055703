import React from 'react';
import classNames from 'classnames';
import {Checkbox} from 'semantic-ui-react';

import './index.scss';

const MailTarget = (props: any) => {
  const {meta, input} = props;

  return (
    <div className="a__mail-target">
      <div className="w__field">
        <div
          className={classNames('target', {
            is_disabled: props.disabled,
          })}
        >
          <Checkbox
            {...props}
            radio
            onChange={(e, {checked}) => input.onChange(checked)}
            checked={Boolean(input.value)}
            id="all"
            label="全ユーザーに送信する"
          />
        </div>

        <div
          className={classNames('target', {
            is_disabled: props.disabled,
          })}
        >
          <Checkbox
            {...props}
            radio
            onChange={(e, {checked}) => input.onChange(!checked)}
            checked={Boolean(!input.value)}
            id="preuser-only"
            label="事前登録者＆β版登録者のみに送信する"
          />
        </div>
      </div>

      {meta.touched && meta.error && (
        <span className="form__error">{meta.error}</span>
      )}
    </div>
  );
};

export default MailTarget;
