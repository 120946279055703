import _ from 'lodash';
import snakeCaseKeys from 'snakecase-keys';
import {app} from '@/lib/app';
import {IMailsSortState, IMails} from '@/@types/models/mails';

const {axios} = app;
const env = app.env.get();
const resources = '/massEmails';
const limit = 10;

export const getPagination = async (
  current: number,
  searchWords: string[],
  sortState: IMailsSortState
): Promise<any> => {
  try {
    let url;

    // クエリパラメータのソートを判定
    const sort =
      sortState.order === 'desc'
        ? `%2b${_.snakeCase(sortState.key)}`
        : `%2d${_.snakeCase(sortState.key)}`;

    // クエリパラメータの検索ワードを判定
    if (searchWords.length !== 0) {
      const search = searchWords.join('%20');

      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}&keyword=${search}`;
    } else {
      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}`;
    }

    const res = await axios.base.get(url);

    const result = {
      pages: res.data.pages,
      totalCount: Number(res.headers['x-total-count']),
      totalPages: Number(res.headers['x-total-pages']),
      current,
    };

    return result;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const getOne = async (id: number) => {
  try {
    const {data} = await axios.base.get(`${resources}/${id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const update = async (mails: IMails) => {
  const reqbody = {
    ...mails,
  };

  try {
    const {data} = await axios.auth.put(
      `${resources}/${mails.id}`,
      snakeCaseKeys(reqbody)
    );
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const destroy = async (mails: IMails) => {
  try {
    const {data} = await axios.auth.delete(`${resources}/${mails.id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const create = async (mails: any) => {
  try {
    const reqbody = {
      ...mails,
    };

    const {data} = await axios.auth.post(
      `${resources}`,
      snakeCaseKeys(reqbody)
    );
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};
