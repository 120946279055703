import {useEffect} from 'react';
import _ from 'lodash';
import {getDetailById} from '@/lib/utils/array';

export const lifecycle = {
  componentDidMount(props: any) {
    const {actions} = props;

    useEffect(() => {
      const id = Number(props.match.params.id);
      const active = props.news.pages.active;

      if (window.isInitial) {
        (async () => {
          await actions.news.getOne(id);
        })();
      } else {
        const detail = getDetailById(id, active);
        actions.news.updateDetail(detail);
      }
    }, []);
  },
};
