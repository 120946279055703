import {createActions} from 'redux-actions';
import {app} from './../../lib/app';

const {me}: any = createActions({
  me: {
    async get() {
      try {
        const payload = await app.auth.getCurrentUser();
        return payload;
      } catch (err) {
        throw new Error(err as any);
      }
    },
  },
});

export {me};
