const mails = (values: any) => {
  const errors: any = {};

  if (!values.titleText) {
    errors.titleText = ' タイトルは必須です';
  }

  if (!values.bodyText) {
    errors.bodyText = ' 本文は必須です';
  }

  return errors;
};

export {mails};
