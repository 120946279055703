import {DeepPartial} from 'redux';
import {IMailsState} from '@/@types/models/mails';

const news: DeepPartial<IMailsState> = {
  pages: {
    active: [],
  },
  detail: {},
  searchWords: [],
  sortState: {
    order: 'asc',
    key: 'id',
  },
  totalCount: 0,
  totalPages: 0,
  current: 1,
};

export default news;
