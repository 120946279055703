import {compose} from 'redux';
import {reduxForm} from 'redux-form';
import {withRouter} from 'react-router';

export const enhance: any = compose(
  withRouter,
  reduxForm({
    form: 'ResetPassword',
  })
);
