import {bindActionCreators, compose} from 'redux';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {actions} from './../../../redux/actions';
import {validations} from './../../../lib/validations';
import {withRouter} from 'react-router';

const mapStateToProps = (state: any) => ({
  me: state.me,
  initialValues: state.me,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      me: bindActionCreators(actions.me, dispatch),
    },
  };
};

export const enhance: any = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'MyPageMain',
    enableReinitialize: true,
  })
);
