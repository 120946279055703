import * as React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {paths} from '@/config/paths';
import {enhance} from './enhancer';

import './index.scss';

/* Routes */
import SignInRoutes from '../../Children/SignInRoutes';
import ResetPasswordRoutes from '../../Children/ResetPasswordRoutes';

function BeforeRoutes() {
  return (
    <div className="a__before_routes">
      <Switch>
        <Route path={paths.signIn.main} component={SignInRoutes} />
        <Route
          path={paths.resetPassword.main}
          component={ResetPasswordRoutes}
        />
        <Redirect to={paths.signIn.main} />
      </Switch>
    </div>
  );
}

export default enhance((props: any) => <BeforeRoutes {...props} />);
