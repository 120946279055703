import _ from 'lodash';
import snakeCaseKeys from 'snakecase-keys';

import {app} from '@/lib/app';
import {IBansSortState, IBan} from '@/@types/models/bans';

const {axios} = app;
const resources = '/ngwords';
const limit = 50;

export const getPagination = async (
  current: number,
  searchWords: string[],
  sortState: IBansSortState
): Promise<any> => {
  try {
    let url;

    // utils関数化する (makeHitColumnsQuery('name', 'price', 'prefectures'))
    // クエリパラメータのソートを判定
    const sort =
      sortState.order === 'desc'
        ? `%2b${_.snakeCase(sortState.key)}`
        : `%2d${_.snakeCase(sortState.key)}`;

    // クエリパラメータの検索対象カラムを定義
    const columns = 'name%20price';

    // クエリパラメータの検索ワードを判定
    if (searchWords.length !== 0) {
      const search = searchWords.join('%20');

      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}&keyword=${search}`;
    } else {
      url = `${resources}_paging?page=${current}&limit=${limit}&sort=${sort}`;
    }

    const res = await axios.auth.get(url);

    const result = {
      pages: res.data.pages,
      totalCount: Number(res.headers['x-total-count']),
      totalPages: Number(res.headers['x-total-pages']),
      current,
    };

    return result;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const create = async (ban: any) => {
  try {
    const reqbody = {
      ...ban,
    };

    const {data} = await axios.auth.post(
      `${resources}`,
      snakeCaseKeys(reqbody)
    );
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const destroy = async (ban: IBan) => {
  try {
    const {data} = await axios.auth.delete(`${resources}/${ban.id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};

export const getOne = async (id: number) => {
  try {
    const {data} = await axios.auth.get(`${resources}/${id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};
