const main = (values: any) => {
  const errors = {};

  if (!values?.['email']) {
    errors['email'] = 'メールアドレスを入力してください';
  } else {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values?.['email']))
      errors['email'] = 'メールアドレスが正しい形式ではありません';
    else if (!/^[0-9+a-zA-Z-@._]+$/i.test(values?.['email']))
      errors['email'] = 'メールアドレスは半角で入力して下さい';
  }

  return errors;
};

const rule = new RegExp(
  `^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[\\d])[a-zA-Z\\d]{8,50}$`
);

const confirm = (values: any) => {
  const errors = {};

  if (!values?.['code']) {
    errors['code'] = '認証コードを入力してください';
  } else if (!/^[0-9]{6,6}$/.test(values?.['code'])) {
    errors['code'] = '認証コードは6桁です';
  }

  if (!values?.['password']) {
    errors['password'] = 'パスワードを入力してください';
  } else if (values?.['password']?.length < 8) {
    errors['password'] = `パスワードは8文字以上で入力してください`;
  } else if (!rule.test(values?.['password'])) {
    errors['password'] = 'パスワードに大文字・小文字・数字を含んでください';
  }

  if (!values?.['confirmPassword']) {
    errors['confirmPassword'] = '確認用のパスワードを入力してください';
  } else if (values?.['confirmPassword'] !== values?.['password']) {
    errors['confirmPassword'] = 'パスワードが一致していません';
  }

  return errors;
};

export const resetPassword = {
  main,
  confirm,
};
