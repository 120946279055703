import _ from 'lodash';
import snakeCaseKeys from 'snakecase-keys';
import {app} from '@/lib/app';
import axios from 'axios';

const env = app.env.get();
const resources = '/sales/detail';

export const getDetail = async (id: number) => {
  try {
    const {data} = await axios.get(`${env.GO_ENDPOINT}${resources}/${id}`);
    return data;
  } catch (err) {
    throw new Error(err as any);
  }
};
