import {app} from '@/lib/app';
import {ISale} from '@/@types/models/sales';

const {axios} = app;
const resources = '/sales';

/** ダミーの出品魚作成 */
export const create = async (body: ISale, userId: number) => {
  try {
    const {data} = await axios.auth.post(`${resources}/${userId}`, body);

    return data;
  } catch (error) {
    throw new Error(error as any);
  }
};
