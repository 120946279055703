import lAxios from 'axios';
import {env as myEnv} from './env';
import {getIdToken} from '@/migration/auth';

const env = myEnv.get();

const base = lAxios.create({
  baseURL: env.SLS_GO_ENDPOINT,
  timeout: 30000,
});
base.defaults.headers.post['Content-Type'] = 'application/json';

const auth = lAxios.create({
  baseURL: env.SLS_GO_ENDPOINT,
  timeout: 30000,
});

auth.defaults.headers.post['Content-Type'] = 'application/json';
auth.interceptors.request.use(
  async (config) => {
    const token = await getIdToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const axios = {base, auth};
