import React from 'react';

import Thumb from '../Thumb';
import {Props} from './types';

import './index.scss';

const ThumbContainer = (props: Props) => {
  return (
    <>
      {props.droppedImages.length === 0 ? null : (
        <div className="thumb_wrapper_aside">
          {props.droppedImages.map((f) => (
            <Thumb key={f.original || f} {...props} file={f} />
          ))}
        </div>
      )}
    </>
  );
};

export default ThumbContainer;
