import {useEffect} from 'react';

import {config} from '@/config';

const {paths} = config;

const regex = /\/(dummySales|mypage)\/*/;

export const lifecycle = {
  componentDidMount: (props: any) => {
    useEffect(() => {
      props.actions.me.get();
    }, []);

    // ダミーユーザーの遷移できるページを制御
    if (props.me.level && props.me.level === '0') {
      // ダミー出品魚とマイページは許可
      if (!regex.test(props.location.pathname)) {
        props.history.push(paths.dummySales.main);
      }
    }
  },
};
