import * as React from 'react';
import {TextArea as UiTextArea} from 'semantic-ui-react';
import classNames from 'classnames';

export const TextArea = (props: any) => {
  const {dark, fluid, outline, input, meta, disabled, ...rest} = props;

  return (
    <div
      className={classNames('gc__textarea', {
        normal: !dark,
        dark: dark,
      })}
    >
      <UiTextArea
        className={classNames({
          fluid: fluid,
          outline: outline,
          diabled: disabled || meta.submitting,
        })}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        disabled={disabled || meta.submitting}
        {...rest}
      />
      {meta.touched &&
        ((meta.error && <div className="form__error">{meta.error}</div>) ||
          (meta.warning && (
            <div className="form__warning">{meta.warning}</div>
          )))}
    </div>
  );
};
