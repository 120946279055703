import React from 'react';
import classNames from 'classnames';

import {TextArea} from 'semantic-ui-react';

import './index.scss';

const SemanticTextArea = (props: any) => {
  const {meta, input, dark, fluid, ...rest} = props;

  return (
    <div
      className={classNames('semantic-textarea', {
        normal: !dark,
        dark,
      })}
    >
      <TextArea
        {...rest}
        className={classNames({
          fluid,
          disabled: props.disabled || meta.submitting,
        })}
        name={input.name}
        value={input.value}
        onChange={(event, field) => {
          const v = field.value as string;

          if (field.maxLength < v.length) {
            return;
          } else {
            input.onChange(field.value);
          }
        }}
        maxLength={props.maxLength || 255}
        disabled={props.disabled || meta.submitting}
      />
      {meta.touched && meta.error && (
        <div className="form__error">{meta.error}</div>
      )}
    </div>
  );
};

export default SemanticTextArea;
