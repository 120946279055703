import {withState} from 'recompose';
import {bindActionCreators, compose} from 'redux';
import {formValueSelector, reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import notify from '@/migration/notification';

import {actions} from './../../../redux/actions';
import {utils} from '../../../lib/utils';
import {validations} from '../../../lib/validations';
import {constants} from '@/constants';

// 追加
const selector = formValueSelector('dummySalesEdit');

const mapStateToProps = (state: any) => {
  return {
    sales: state.sales,
    // 追加
    soilValue: selector(state, 'soil'),
    pesticidesValue: selector(state, 'pesticides'),

    categoryValue: selector(state, 'category'),
    breedValue: selector(state, 'breed'),
    gradeValue: selector(state, 'grade'),
    rankValue: selector(state, 'rank'),

    initialValues: {
      ...state.sales.detail,
      sellImages: state.sales.detail.images,
      fishingYear: utils.date.format(state.sales.detail.fishingDay, 'YYYY'),
      fishingMonth: utils.date.format(state.sales.detail.fishingDay, 'M'),
      fishingDay: utils.date.format(state.sales.detail.fishingDay, 'D'),
      closeBidMin: utils.date.exhibitedDate(
        state.sales.detail.postedAt,
        state.sales.detail.closeBidAt
      ),
      weight:
        state.sales.detail.weight === 0 ? null : state.sales.detail.weight,
      price: state.sales.detail.startPrice,
      fixPrice:
        state.sales.detail.fixPrice > 10000000
          ? null
          : state.sales.detail.fixPrice,
      soilAdditionalInfo:
        typeof state.sales.detail.soilAdditionalInfo === 'string'
          ? (() => {
              const trues = state.sales.detail.soilAdditionalInfo.split(',');
              return constants.soilAdditionalInfo.map((e) =>
                trues.includes(e.value)
              );
            })()
          : [],
      pesticidesAdditionalInfo:
        typeof state.sales.detail.pesticidesAdditionalInfo === 'string'
          ? (() => {
              switch (state.sales.detail.pesticides) {
                case 2:
                  return state.sales.detail.pesticidesAdditionalInfo;
                case 3:
                  /* eslint-disable */
                  const trues = state.sales.detail.pesticidesAdditionalInfo.split(
                    ','
                  );
                  const result = constants.pesticidesAdditionalInfo.map((e) =>
                    trues.includes(e.value)
                  );
                  result.unshift('');
                  /* eslint-enable */
                  return result;
                default:
                  return [];
              }
            })()
          : [],
    },
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      sales: bindActionCreators(actions.sales, dispatch),
    },
  };
};

export const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'dummySalesEdit',
    validate: validations.dummySales.edit,
    enableReinitialize: true,
    onSubmitFail() {
      notify('入力内容をご確認ください。', 'error', undefined, 5);
    },
  }),
  withRouter,
  withState('isLoading', 'updateLoading', false),
  withState('isImageLoading', 'uploadIsImageLoading', false),
  withState('isSellLoading', 'isSellLoadingUpdater', false),
  withState('isModal', 'updateIsModal', false)
);
