import React from 'react';
import {Input} from 'semantic-ui-react';

import './index.scss';

const SemanticInput = (props: any) => {
  const {meta, input} = props;

  return (
    <div className="a__input">
      <Input
        {...props}
        onChange={(event, field) => input.onChange(field.value)}
        onBlur={() => props.input.onBlur(props.input.value)}
      />
      {meta.touched && meta.error && (
        <div className="form__error">{meta.error}</div>
      )}
    </div>
  );
};

export default SemanticInput;
