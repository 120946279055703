import React from 'react';
import ReactTable from 'react-table';
import classNames from 'classnames';
import Pagination from '@/migration/pagination';

import Column from '../Column';
import NoData from '../NoData';
import Loading from '../Loading';
import {TableProps} from './types';

const DEFAULT_PAGE_SIZE = 10;

import './style.scss';

const Table: React.FC<TableProps> = (props) => {
  return (
    <div className="gc__table">
      <div
        className={classNames('b__table', {
          outline: props.outline === true || props.outline === undefined,
        })}
      >
        <ReactTable
          data={props.active}
          columns={props.columns}
          pageSize={props.pageSize || DEFAULT_PAGE_SIZE}
          showPaginationBottom={false}
          className="-striped -highlight"
          loading={props.loading}
          ThComponent={(e) => {
            return (
              <Column
                {...e}
                active={props.active}
                columns={props.columns}
                sortState={props.sortState}
                updateSortState={props.updateSortState}
              />
            );
          }}
          NoDataComponent={() => {
            if (props.loading) {
              return null;
            }
            return (
              <NoData
                noDataMessage={props.noDataMessage || 'データが存在しません'}
              />
            );
          }}
          LoadingComponent={() => {
            if (props.loading) {
              return <Loading />;
            }
            return null;
          }}
        />
      </div>
      <div
        className={classNames('b__pagination', {
          hidden: props.showPagination === false,
          left: props.paginationPosition === 'left',
          center: props.paginationPosition === 'center',
        })}
      >
        <div className="custom__pagination">
          {props.children && <>{props.children}</>}
          <Pagination
            totalCount={props.totalCount}
            current={props.current}
            pageSize={props.pageSize || DEFAULT_PAGE_SIZE}
            changePage={(current) => {
              props.updateCurrent(current);
            }}
            disabled={props.disabled}
            showSizeChanger={props.showSizeChanger}
            {...(props.showSizeChanger && {
              changePageSize: (current, pageSize) => {
                if (props.updatePageSize) {
                  props.updatePageSize(current, pageSize);
                }
              },
            })}
            scrollTop={props.scrollTop}
          />
        </div>
      </div>
    </div>
  );
};

export default Table;
