import * as me from './me';
import * as bans from './bans';
import * as users from './users';
import * as sales from './sales';
import * as news from './news';
import * as mails from './mails';

const repos = {
  me,
  bans,
  users,
  sales,
  news,
  mails,
};

export {repos};
