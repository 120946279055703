import {handleActions} from 'redux-actions';
import {ActionType} from 'redux-promise-middleware';
import {actions} from '../actions';
import {repos} from '../repos';
import {AnyAction} from 'redux';
import {initialState} from './../store/initialState';

const sales = handleActions(
  {
    [`${actions.sales.getPagination}_${ActionType.Fulfilled}`](
      state: any,
      action: AnyAction
    ) {
      return repos.sales.getPagination(state, action.payload);
    },
    [`${actions.sales.updateCurrent}`](state: any, action: any) {
      return repos.sales.updateCurrent(state, action.payload);
    },
    [`${actions.sales.addSearchWord}`](state, action) {
      return repos.sales.addSearchWord(state, action.payload);
    },
    [`${actions.sales.resetSearchWord}`](state) {
      return repos.sales.resetSearchWord(state);
    },
    [`${actions.sales.removeSearchWord}`](state, action) {
      return repos.sales.removeSearchWord(state, action.payload);
    },
    [`${actions.sales.updateSortState}`](state, action) {
      return repos.sales.updateSortState(state, action.payload);
    },
    [`${actions.sales.updateDetail}`](state, action) {
      return repos.sales.updateDetail(state, action.payload);
    },
    [`${actions.sales.getOne}_${ActionType.Fulfilled}`](state, action) {
      return repos.sales.getOne(state, action.payload);
    },
    [`${actions.sales.update}_${ActionType.Fulfilled}`](state, action) {
      return repos.sales.getPagination(state, action.payload);
    },
    [`${actions.sales.destroy}_${ActionType.Fulfilled}`](state, action) {
      return repos.sales.getPagination(state, action.payload);
    },
    [`${actions.sales.updateLoading}`](state: any, action: AnyAction) {
      return repos.sales.updateLoading(state, action.payload);
    },
    [`${actions.sales.create}_${ActionType.Fulfilled}`](state) {
      return repos.sales.create(state);
    },
  },
  initialState
);

export {sales};
