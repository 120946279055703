import {bindActionCreators, compose} from 'redux';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {actions} from './../../../redux/actions';
import {validations} from './../../../lib/validations';
import {withRouter} from 'react-router';

const mapStateToProps = (state: any) => ({
  bans: state.bans,
  initialValues: state.bans.detail,
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      bans: bindActionCreators(actions.bans, dispatch),
    },
  };
};

export const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'bansEdit',
    validate: validations.bans,
    enableReinitialize: true,
  }),
  withRouter
);
