import React from 'react';
import {Dropdown} from 'semantic-ui-react';

import {constants} from '@/constants';

import './index.scss';

const FishingDate = (props: any) => {
  const {fishingYear, fishingMonth, fishingDay, fishingDate} = props;

  return (
    <div className="a__fishing-date">
      <div className="w__field row">
        <div className="column">
          <Dropdown
            fluid
            selection
            options={constants.year}
            value={fishingYear.input.value}
            onChange={(event, field) => fishingYear.input.onChange(field.value)}
          />
          <span>年</span>
        </div>

        <div className="column">
          <Dropdown
            fluid
            selection
            options={constants.month}
            value={fishingMonth.input.value}
            onChange={(event, field) =>
              fishingMonth.input.onChange(field.value)
            }
          />
          <span>月</span>
        </div>

        <div className="column">
          <Dropdown
            fluid
            selection
            options={constants.day}
            value={fishingDay.input.value}
            onChange={(event, field) => fishingDay.input.onChange(field.value)}
          />
          <span>日</span>
        </div>
      </div>

      {fishingDate.meta.error && (
        <div className="form__error">{fishingDate.meta.error}</div>
      )}
    </div>
  );
};

export default FishingDate;
