import {bindActionCreators, compose} from 'redux';
import {reduxForm} from 'redux-form';
import {connect} from 'react-redux';
import {actions} from '../../../redux/actions';
import {validations} from '../../../lib/validations';
import {withRouter} from 'react-router';

const mapStateToProps = (state: any) => ({
  mails: state.mails,
  selectedForm: state.form,
  initialValues: {condition: true},
});

const mapDispatchToProps = (dispatch: any) => {
  return {
    actions: {
      mails: bindActionCreators(actions.mails, dispatch),
    },
  };
};

export const enhance: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'MailsCreate',
    validate: validations.mails,
  }),
  withRouter
);
