export const getDetailById = (id: any, data: any, idFormat?: any) => {
  const key = idFormat ? idFormat : 'id';
  const targetId = Number(id);

  const result = data?.find((d) => {
    return targetId === d[key];
  });

  return result;
};
