import {DeepPartial} from 'redux';
import {ISalesState} from '@/@types/models/sales';

const sales: DeepPartial<ISalesState> = {
  pages: {
    active: [],
  },
  detail: {},
  searchWords: [],
  sortState: {
    order: 'asc',
    key: 'saleId',
  },
  totalCount: 0,
  totalPages: 0,
  current: 1,
};

export default sales;
